import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cut'
})
export class CutPipe implements PipeTransform {

  transform(str: string, max:number) {
        if(!str) return;
        if(max == -1)
          return str
        if(str.length > max)
          str = str.substring(0, max)+'...'
        return str;
    }

}
