import {Component, OnInit} from '@angular/core';

export class GlobalSettings {
    data = {
        'inType':null,
        'area':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "globalSettings";
    title = "Business Details";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text', 'required': true},
        about: {'title': 'about', 'value': null, 'type': 'textarea', 'maxlength': 8000},
        times: {'title': 'times', 'value': null, 'type': 'textarea'},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'tel', 'maxlength':10},
        directions: {'title': 'directions', 'value': null, 'type': 'textarea'},
        businessPromote: {'title': 'visibleInArea', 'value': null, 'type': 'boolean'},
        shipments: {'title': 'shipments', 'value': null, 'type': 'boolean'},
        minimumShipping: {'title': 'minimumShipping', 'value': null, 'type': 'float'},
        shippingPrice: {'title': 'shippingPrice', 'value': null, 'type': 'float'},
        shippingRadius: {'title': 'shippingRadius', 'value': null, 'type': 'float'},
        vat: {'title': 'vat', 'value': null, 'type': 'float'},
        keywords: {'title': 'keywords', 'value': null, 'type': 'text'},
        massof: {'title': 'massof', 'value': null, 'type': 'text'},
        type: {
            'title': 'department',
            'showDefault': true, 'value': null, "displayField": 'typeName',
            'filters': {'contentTypeName': 'area', 'fieldName': 'bisType', 'noBusiness': true},
            'type': 'modelscroll', 'modelscroll': 'ModelStatus',
            'keyField': 'id', 'valueField': 'name',
        },
        area: {
            'title': 'area',
            'showDefault': true, 'value': null, "displayField": 'areaName',
            'type': 'modelscroll', 'modelscroll': 'Area',
            'keyField': 'id', 'valueField': 'name',
        },
        inType: {
            'title': 'type',
            'showDefault': true, 'value': null, "displayField": 'inTypeName',
            'filters': {'noBusiness': true, 'fieldName': 'inType', 'contentTypeName': 'globalSettings'},
            'type': 'modelscroll', 'modelscroll': 'ModelStatus',
            'keyField': 'id', 'valueField': 'name', 'setFirstOption': true,
        },
        inSubType: {
            'title': 'subType',
            'showDefault': true, 'value': null, "displayField": 'inSubTypeName',
            'filters': {'noBusiness': true},
            'type': 'modelscroll', 'modelscroll': 'ModelStatus',
            'keyField': 'id', 'valueField': 'name',
        },

        orderStatusChangeTime: {'title': 'orderStatusChangeTime', 'value': null, 'type': 'float'},
        orderTypes: {
            'title': 'orderTypes',
            'value': null,
            'type': 'text',
            'textOptions': ['sit', 'takeAway', 'pickup', 'delivery'],
            'textOptionsMultiple': true
        },
        menuText: {'title': 'menuText', 'value': null, 'type': 'text', 'textOptions': ['menu', 'catalog']},
    }
}
