import {Component, OnInit} from '@angular/core';

export class Business {
    data = {
        'GlobalSettingsData':{
            'shippingPrice':null,
            'vat':null,
            'orderStatusChangeTime':null,
            'name':null,
            'massof':null,
            'about':null,
            'times':null,
            'cellphone':null,
            'directions':null,
            'minimumShipping':null,
            'useTerms':null,
        },
        'prefix':'',
        'id':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "business";
    title = "Business";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'Name', 'value': null, 'type': 'text'},
        prefix: {'title': 'Prefix', 'value': null, 'type': 'text'},
        username: {'title': 'username', 'value': null, 'type': 'text'},
        password: {'title': 'password', 'value': null, 'type': 'text'},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'text'},

    }
}