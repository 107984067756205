import {Component, OnInit} from '@angular/core';

export class DealGeneralProperty {
    data = {
        'id':null,
        'name':null,
        'images':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "dealGeneralProperty";
    title = "Deal General Property";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text', 'required':true},
        price: {'title': 'price', 'value': null, 'type': 'float'},

    }
}