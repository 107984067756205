import {Injectable} from '@angular/core';
import {NgModule} from '@angular/core';

import {Type} from '@angular/core';

import {DesktopStat} from "../../framework/model/general/desktopStats/desktopStat";
import {ContentType} from "../../framework/model/general/contentType";
import {Puser} from "../../framework/model/puser/puser";
import {PuserType} from "../../framework/model/general/puser/puserType";
import {DesktopLink} from "../../framework/model/general/desktopLink/desktopLink";
import {ColumnModel} from "../../framework/model/columnModel";
import {DesktopStatFilter} from "../../framework/model/general/desktopStats/desktopStatFilter";
import {Signature} from "../../framework/model/general/signature";
import {ModelStatus} from "../../framework/model/modelStatus";
import {GlobalSettings} from "../../framework/model/core/business/globalSettings";
import {AppUser} from "../../framework/model/core/appuser/appuser";
import {Address} from "../../framework/model/core/address/address";
import {BusinessImages} from "../../framework/model/core/business/businessImages";
import {Section} from "../../framework/model/core/sections/section";
import {Item} from "../../framework/model/core/items/item";
import {OrderItem} from "../../framework/model/core/order/orderItem";
import {MainAdvertise} from "../../framework/model/core/advertise/advertise";
import {Deal} from "../../framework/model/core/deal/deal";
import {ItemToDeal} from "../../framework/model/core/deal/itemToDeal";
import {DealProperty} from "../../framework/model/core/deal/dealProperty";
import {DealPropertyType} from "../../framework/model/core/deal/dealPropertyType";
import {DealTypeByProperty} from "../../framework/model/core/deal/dealTypeByProperty";
import {DealGeneralPropertyType} from "../../framework/model/core/deal/dealGeneralPropertyType";
import {DealGeneralProperty} from "../../framework/model/core/deal/dealGeneralProperty";
import {DealGroup} from "../../framework/model/core/deal/dealGroup";
import {ItemToDealGroup} from "../../framework/model/core/deal/itemToDealGroup";
import {Area} from "../../framework/model/core/area/area";
import {Note} from "../../framework/model/general/note/note";
import {Business} from "../../framework/model/core/business/business";
import {Order} from "../../framework/model/core/order/order";
import {ModelToImage} from "../../framework/model/core/general/modelToImage";
import {CartItem} from "../../framework/model/core/cart/cartItem";
import {Coupon} from "../../framework/model/core/coupon/coupon";

export class AdItem {
    constructor(public component: Type<any>) {
    }
}

@NgModule({})
@Injectable()
export class ModelClasses {

    classes = {
        'business': new AdItem(Business),
        'globalsettings': new AdItem(GlobalSettings),
        'appuser': new AdItem(AppUser),
        'address': new AdItem(Address),
        'shipmentadress': new AdItem(Address),
        'order': new AdItem(Order),
        'image': new AdItem(Image),
        'modeltoimage': new AdItem(ModelToImage),

        'businessimages': new AdItem(BusinessImages),
        'section': new AdItem(Section),
        'item': new AdItem(Item),
        'orderitem': new AdItem(OrderItem),
        'mainadvertise': new AdItem(MainAdvertise),
        //
        'deal': new AdItem(Deal),
        'itemtodeal': new AdItem(ItemToDeal),
        'dealproperty': new AdItem(DealProperty),
        'dealpropertytype': new AdItem(DealPropertyType),
        'dealtypebyproperty': new AdItem(DealTypeByProperty),
        'dealgeneralpropertytype': new AdItem(DealGeneralPropertyType),
        'dealgeneralproperty': new AdItem(DealGeneralProperty),
        'dealgroup': new AdItem(DealGroup),
        'itemtodealgroup': new AdItem(ItemToDealGroup),

        'area': new AdItem(Area),

        'cartitem': new AdItem(CartItem),
        'coupon': new AdItem(Coupon),

        'modelstatus': new AdItem(ModelStatus),
        'contenttype': new AdItem(ContentType),
        'signature': new AdItem(Signature),
        'puser': new AdItem(Puser),
        // 'genericmodel': new AdItem(GenericModel),
        'desktopstat': new AdItem(DesktopStat),
        'desktopstatfilter': new AdItem(DesktopStatFilter),
        'desktoplink': new AdItem(DesktopLink),
        'columnmodel': new AdItem(ColumnModel),
        // 'info': new AdItem(Info),
        'pusertype': new AdItem(PuserType),
        'note': new AdItem(Note),
    }

    constructor() {
    }

    //depricated!
    public getClass(modelPrefix) {

        // console.log("load class: ");
        // console.log(modelPrefix);
        let pref = modelPrefix.toLowerCase();
        // console.log(pref);
        // console.log(this.classes[pref]);

        return this.classes[pref] ? this.classes[pref].component : null;
    }

    public getModelClass(modelPrefix) {
        // console.log(modelPrefix);
        let pref = modelPrefix.toLowerCase();
        //console.log(pref);
        // console.log(this.classes[pref]);
        return this.classes[pref] ? this.classes[pref].component : null;
    }
}