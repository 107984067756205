export class AppUser {
    data = {
        'id': null,
        'name': null,
        'username': null,
        'cellphone':null,
        'email':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "appuser";
    title = "User";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text'},
        area: {'title': 'area', 'value': null, 'type': 'text'},
        username: {'title': 'username', 'value': null, 'type': 'text'},
        password: {'title': 'password', 'value': null, 'type': 'text'},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'text'},
    }
}