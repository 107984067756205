import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage";
import {Events} from "@ionic/angular";
import {ModelInterface, ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {AppConfig} from "../conf/main";
import {BusinessImages} from "../../framework/model/core/business/businessImages";
import {Business} from "../../framework/model/core/business/business";
import {ActivatedRoute} from "@angular/router";

@Injectable()
export class BusinessService {
    public business: Business;
    public businessImages: BusinessImages;
    public orderTypes;

    public staticPrefix:string;

    constructor(private modelProvider: ModelProvider, public route:ActivatedRoute,
                public events: Events, public storage: Storage) {
    }

    start() {
        var prefix = AppConfig.bisPrefix;

        if(prefix)
        {
            this.setBisByPrefix(prefix);
            return;
        }
        //
        // this.route.queryParams.subscribe(params => {
        //     prefix = params['pref']
        //     if(prefix)
        //         this.setBisByPrefix(prefix);
        // });
    }

    // public setInitBisByPrefix(prefix) {
    //     this.staticPrefix = prefix;
    //     this.getBis({'pref': prefix}).then((res: ModelInterface) => {
    //         if (!res.models.length) return;
    //         this.setBis(res.models[0])
    //     })
    // }

    public setBisByPrefix(prefix) {
        AppConfig.bisPrefix = prefix;

        this.getBis({'pref': prefix}).then((res: ModelInterface) => {
            if (!res.models.length) return;
            this.setBis(res.models[0]);
        })
    }

    public setBis(bis) {
        this.business = bis;

        AppConfig.bisPrefix = bis.data.prefix;

        this.events.publish('businessSets', {'data': this.business})
        this.setImages();
    }

    public getOrderTypes() {
        return this.orderTypes;
    }

    public setUserBusiness() {
        AppConfig.bisPrefix = null;
        this.getBis().then((res) => {
            this.setBis(res.models[0])
        })
    }

    getBis(filters = {}): Promise<ModelInterface> {
        return new Promise((resolve, reject) => {
            this.modelProvider.list('business', filters).then((res: ModelInterface) => {
                resolve(res)
            });
        });
    }

    setImages() {
        this.businessImages = null;
        this.modelProvider.list('businessImages', {'business': this.business.data.id}).then((res) => {
            this.businessImages = res['models'][0]
            this.events.publish('businessImagesSets', {'data': this.businessImages})
        });
    }

    public getBusiness() {
        return this.business
    }

    public removeBusiness() {
        if(this.staticPrefix)
            return;
        AppConfig.bisPrefix = null;
        this.business = null;
    }

    public getImages() {
        return this.businessImages
    }

    private parseQueryString() {

        var str = window.location.search;
        var objURL = {};

        // str.replace(
        //     new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        //     function ($0, $1, $2, $3) {
        //         objURL[$1] = $3;
        //     }
        // );
        return objURL;
    }
}








