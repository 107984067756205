import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from "@ionic/angular";
import {HttpClient} from "@angular/common/http";
import {TranslateLoader, TranslateModule, TranslatePipe} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {IonicStorageModule} from "@ionic/storage";
import {NoteComponent} from "../../framework/components/core/note/note.component";
import {ApplistComponent} from "../../framework/components/list/app-list/app-list.component";
import {AppImgComponent} from "../../framework/components/general/appimg/app-img.component";
import {DisplayfieldComponent} from "../../framework/components/field/displayfield/displayfield.component";
import {AutofieldComponent} from "../../framework/components/field/autofield/autofield.component";
import {ModelscrollComponent} from "../../framework/components/field/modelscroll/modelscroll.component";
import {AppModelScrollComponent} from "../../framework/components/field/modelscroll/app-model-scroll/app-model-scroll.component";
import {PagingComponent} from "../../framework/components/list/paging/paging.component";
import {CutPipe} from "../../framework/pipes/cut/cut.pipe";
import {ObjectKeysPipe} from "../../framework/pipes/object-keys/object-keys.pipe";
import {AutoCompleteComponent} from "../../framework/components/field/auto-complete/auto-complete.component";
import {ModeloptionscrollComponent} from "../../framework/components/field/modeloptionscroll/modeloptionscroll.component";
import {AppfileIonicComponent} from "../../framework/components/file/appfile-ionic/appfile-ionic.component";
import {AppfileWebComponent} from "../../framework/components/file/appfile-web/appfile-web.component";
import {AppfileComponent} from "../../framework/components/file/appfile/appfile.component";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AppHeaderComponent} from "../../core/components/app-header/app-header.component";
import {PriceComponent} from "../../framework/components/core/price/price.component";
import {AppLoadingComponent} from "../../framework/components/core/app-loading/app-loading";
import {DynamicSliderComponent} from "../../framework/components/core/dynamic-slider/dynamic-slider.component";
import {DynamicSliderBodyComponent} from "../../framework/components/core/dynamic-slider-body/dynamic-slider-body.component";
import {ValidFormComponent} from "../../framework/components/core/valid-form/valid-form.component";
import {AppBackgroundComponent} from "../../core/components/app-background/app-background.component";
import {OrderAddressComponent} from "../../core/pages/cart/components/order-address/order-address.component";
import {AppConfig} from "../../core/conf/main";
import {CouponsComponent} from "../../core/pages/cart/components/coupons/coupons.component";
import {NewOrderComponent} from "../../core/pages/order/components/new-order/new-order.component";

export function HttpLoaderFactory(http: HttpClient) {
    // return new TranslateHttpLoader(http);
    let tran = new TranslateHttpLoader(http, AppConfig.assets+'i18n/', '.json');
    return tran
}

@NgModule({
    providers: [],
    imports: [
        CommonModule,
        IonicModule.forRoot(),
        FormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicStorageModule.forRoot(),

    ],
    entryComponents: [
        NoteComponent,
        OrderAddressComponent,
        CouponsComponent,
        NewOrderComponent,
    ],
    exports:
        [
            AppHeaderComponent,
            AppBackgroundComponent,

            PriceComponent,
            AppLoadingComponent,
            DynamicSliderComponent,
            DynamicSliderBodyComponent,
            ValidFormComponent,

            TranslateModule,
            ApplistComponent,
            AppImgComponent,
            DisplayfieldComponent,
            AutofieldComponent,
            ModelscrollComponent,
            AppModelScrollComponent,
            PagingComponent,

            CutPipe,
            ObjectKeysPipe

        ],
    declarations: [
        AppHeaderComponent,
        AppBackgroundComponent,

        OrderAddressComponent,
        CouponsComponent,
        NewOrderComponent,

        PriceComponent,
        AppLoadingComponent,
        DynamicSliderComponent,
        DynamicSliderBodyComponent,
        ValidFormComponent,
        NoteComponent,

        ApplistComponent,
        AppImgComponent,
        DisplayfieldComponent,
        AutofieldComponent,

        AutoCompleteComponent,
        ModeloptionscrollComponent,
        ModelscrollComponent,
        AppModelScrollComponent,
        PagingComponent,

        CutPipe,
        ObjectKeysPipe,

        AppfileIonicComponent,
        AppfileWebComponent,
        AppfileComponent,
    ],
})
export class GlobalModule {
}


export interface ResObject {
    models?: any;
    data?: any;
}

export interface CostumRes {
    res?: any;
}

export interface ModelObject {
    data?: ResData;
}

interface ResData {
    id?: object;
}

export interface FieldDataObject {
    calFunc?: any,
    selfTemplate?: any,
    readonly?: boolean,
    displayField?: string,
    type?: any,
    oneToOne?: any,
    extFieldName?: any,
    price?: any,
    nis?: any,
    precents?: any,
    addText?: any,
    modelDataKey?: any,
    selectMultiple?: boolean,
    title?: any,
    height?: any,
    min?: any
    addParams?: any,
    maxlength?: any,
    bigarea?: boolean,
    editTemplate?: any,
    defaultValue?: any,
}