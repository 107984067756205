import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
