import { Component, OnInit } from '@angular/core';

export class DesktopStat {
    data = {'title': null, 'objectId': null, 'contentTypeName': null};

    constructor(public data1:any) {
        this.data = data1;
    }

    icon = "stats";
    frameworkModel = true;
    //shortHeaders = [];
    prefix = "desktopStat";
    title = "DesktopStat";
    defaultHeaders =
        ['name', 'countField', 'contentType', 'puserType'];
    dataFields = {
        "filters": {'value': null, 'type': 'number', 'readonly': true, 'selfTemplate': true},
        "id": {'value': null, 'type': 'number', 'readonly': true},
        "created": {'value': null, 'type': 'date', 'readonly': true},
        "name": {'value': null, 'type': 'text', 'required': true},
        "countField": {'value': null, 'type': 'text'},
        //"employee": {
        //    'value': null, 'type': 'modelscroll', 'displayField': 'employeeName',
        //    'modelscroll': 'User',
        //    'keyField': 'id', 'valueField': 'username', 'readonly': true
        //},

        "desktopStatFilter": {'type': 'oneToMany', 'class': 'DesktopStatFilter', 'foreignKey': 'desktopStat'},
        "contentType": {
            'required': true,
            'value': null, 'type': 'modelscroll', 'displayField': 'contentTypeName',
            'modelscroll': 'ContentType', 'keyField': 'id', 'valueField': 'model'
        },
        "puserType": {
            'showDefault': true, 'value': null, 'type': 'modelscroll',
            'modelscroll': 'PuserType', 'required': true,
            'displayField': 'puserTypeName', 'keyField': 'id', 'valueField': 'name'
        },
    }
}

    //        selfAddForm:true,
    //        getScrollFieldDisplay: function (desktopStat, fieldName)
    //        {
    //            var fields = {'name': desktopStat.data.contentTypeName + ": " + desktopStat.data.name,}
    //            return fields[fieldName] ? fields[fieldName] : false;
    //        },
    //        getData: function (desktopStatData)
    //        {
    //            return this.data;
    //        },
    //        setData: function (desktopStatData)
    //        {
    //            angular.extend(this, {'data': desktopStatData});
    //        },
    //
    //        piechart: function (desktopStat)
    //        {
    //            $timeout(function ()
    //            {
    //                var pie = [];
    //                for (var key in desktopStat.data.filtersRes)
    //                    pie.push([desktopStat.data.filtersRes[key][0],
    //                        desktopStat.data.filtersRes[key][1]])
    //
    //                var pieChart = c3.generate({
    //                    bindto: '#pie-chart' + desktopStat.data.id,
    //                    data: {
    //                        columns: pie,
    //                        type: 'donut'
    //                    },
    //                    donut: {
    //                        label: {
    //                            format: function (value)
    //                            {
    //                                return value;
    //                            }
    //                        }
    //                    }
    //                });
    //            }, 10);
    //        }
    //
    //    };
    //    return DesktopStat;
    //}]);