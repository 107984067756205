import {Item} from "../items/item";

export class OrderItem {
    data = {
        'itemData': null,
        'dealProperties': null,
        'unitPrice': null,
        'comments': null,
    };

    constructor(public data1: any) {
        this.data = data1;

        if(this.data && this.data.itemData)
            this.data.itemData = new Item(this.data.itemData)
    }

    //shortHeaders = [];
    prefix = "orderItem";
    title = "Order Item";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        itemId: {'title': 'Item', 'value': null, 'type': 'text'},
    }
}
