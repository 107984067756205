import {TranslateService} from "@ngx-translate/core";

export class Address {
    data = {
        'city': null,
        'street': null,
        'homeNumber': null,
        'floor': null,
        'apt': null,
        'onRadius': null,
        'appuser': null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    getAddress() {
        if(!this.data) return '';
        let address = this.data.city + ', ' + this.data.street + ' ' + this.data.homeNumber;
        // address += this.data.floor ?', '+ this.translate.instant('floor') + ' ' + this.data.floor : '';
        // address += this.data.apt ?', '+ this.translate.instant('apt') + ' ' + this.data.apt : '';
        return address
    }

    //shortHeaders = [];
    prefix = "shipmentAdress";
    title = "Address";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        onRadius: {'title': 'onRadius', 'value': null, 'type': 'boolean', 'readonly': true},
        state: {'title': 'state', 'value': null, 'type': 'text'},
        city: {'title': 'city', 'value': null, 'type': 'text', 'required': true, 'maxlength': 15},
        street: {'title': 'street', 'value': null, 'type': 'text', 'required': true, 'maxlength': 15},
        homeNumber: {'title': 'homeNumber', 'value': null, 'type': 'number', 'required': true, 'maxlength': 4},
        floor: {'title': 'floor', 'value': null, 'type': 'number', 'maxlength': 4},
        apt: {'title': 'apt', 'value': null, 'type': 'number', 'maxlength': 4},
    }


}