import {Component, OnInit} from '@angular/core';

export class DealTypeByProperty {
    data = {};

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "dealTypeByProperty";
    title = "Deal Type By Property";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},

    }
}