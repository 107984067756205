import {Component, Input, OnInit} from '@angular/core';
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-img',
  templateUrl: './app-img.component.html',
  styleUrls: ['./app-img.component.scss'],
})
export class AppImgComponent implements OnInit {
    @Input()
    src: object;

    @Input()
    fieldkey: string;

    @Input()
    datakey: string;

    @Input()
    boxStyle: object = {};

    @Input()
    style: object = {};

    @Input()
    height: any = 80;

    fullsrc: string;

    @Input()
    background:boolean = true

    @Input()
    showImage:boolean = true

    @Input()
    thumbnail:boolean = true

    constructor(public platform: Platform) {
    }

    ngOnInit() {
        this.setFullsrc()

        if(this.background)
        {
            this.style['height'] = this.height+'px'
            // this.platform.ready().then((readSource) => {
            //     this.style['width'] = this.platform.width()+'px';
            // });
            this.style['background-image'] = 'url('+this.fullsrc+')';
        }


        var obj = {}
        for(var k in this.style)
            obj[k] = this.style[k];
        for(var k in this.boxStyle)
            obj[k] = this.boxStyle[k];
        this.boxStyle = obj;

    }

    setFullsrc() {
        if (!this.src) {
            this.fullsrc = "assets/dashboard/noimage.png";
            return;
        }

        if (this.datakey)
            this.fullsrc = this.src[this.datakey][this.fieldkey];
        else
            this.fullsrc = this.src[this.fieldkey];

        if(this.thumbnail)
            this.fullsrc+='.thumbnail';

    }
}
