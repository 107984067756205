import { Component, OnInit } from '@angular/core';

export class ContentType {
	data = {};

	constructor(public data1: any) {
		this.data = data1;
	}

	frameworkModel = true;
	shortHeaders = [];
	prefix = "contentType";
	title = "Content Type";
	tableFields =
		['id', 'model'];
	dataFields = {
		id: { 'title': 'ID', 'value': null, 'type': 'number', 'readonly': true },
		model: { 'title': 'Name', 'value': null, 'type': 'text', 'readonly': true },
	}
}
