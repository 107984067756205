import {Component, OnInit} from '@angular/core';

export class Section {
    data = {
        'id': null,
        'name': null,
        'description': null,
        'images': []
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "section";
    title = "Section";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text', 'required':true},
        description: {'title': 'description', 'value': null, 'type': 'textarea', 'required':false, 'maxlength':300},
        includeInMenu: {'title': 'includeInMenu', 'value': true, 'type': 'boolean'},
    }


}
