import {Component, OnInit} from '@angular/core';

export class DealGroup {
    data = {};

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "dealGroup";
    title = "IDeal Group";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        business: {'title': 'business', 'value': null, 'type': 'text', 'readonly': true},
        title: {'title': 'title', 'value': null, 'type': 'text'},
        required: {'title': 'required', 'value': null, 'type': 'boolean'},
    }
}