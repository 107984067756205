import {Component, OnInit} from '@angular/core';

export class DealPropertyType {
    data = {
        id:null,
        properties:null,
        generalProperty:null,
        generalType:null,
        active:null,
        type:null,
        default:null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "dealPropertyType";
    title = "Deal Property Type";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text'},
        amount: {'title': 'amount', 'value': null, 'type': 'text'},
        multiple: {'title': 'multipleChoose', 'value': null, 'type': 'boolean'},

    }
}