import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Note} from "../../../model/general/note/note";
import {Events, NavController, PopoverController} from "@ionic/angular";
import {ActivatedRoute} from "@angular/router";
import {ModelProvider} from "../../../providers/modelProvider/ModelProvider";

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {

  public title:string;
  public key:string;
  public text:string;
  public note:Note;

  constructor(public events:Events, public route:ActivatedRoute, public popoverctrl:PopoverController, public modelProvider:ModelProvider) {
     this.route.queryParams.subscribe(params => {
          this.key = params['key']
          this.text = params['text']
          this.title = params['title']
      });
  }

  ngOnInit() {
      let me = this;
      setTimeout(function()
      {
          if(this.key)
            me.getNote()
      },300)
  }

  getNote(){
    this.modelProvider.list('note', {'key': this.key}).then((res: Note) => {
        this.note = res['models'][0]
        this.text = this.note.data.text;
    });

  }

  close()
  {
      this.popoverctrl.dismiss();
  }
}
