export class Coupon {
    data = {
        'id': null,
        "code": null,
        "discount": null,
        "isPercentage": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "coupon";
    title = "Coupon";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        code: {'title': 'prefix', 'value': null, 'type': 'text'},
        discount: {'title': 'prefix', 'value': null, 'type': 'text'},
        isPercentage: {'title': 'prefix', 'value': null, 'type': 'text'},
    }
}
