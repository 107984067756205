import {Component, OnInit} from '@angular/core';

export class DealProperty {
    data = {
        id:null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "dealProperty";
    title = "DealProperty";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'Name', 'value': null, 'type': 'text'},
        price: {'title': 'Price', 'value': null, 'type': 'float', 'nis':true},
    }
}