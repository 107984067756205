import { Component, OnInit } from '@angular/core';

export class Note {
    data = {
        'key':null,
        'text':null,
    };

    constructor(public data1:any) {
        this.data = data1;
    }

    icon = "mail-open"
    editType = 'window';
    frameworkModel = true;
    prefix = "note";
    tableFields = [];
    dataFields = {
        "id": {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        "created": {'value': null, 'type': 'date', 'readonly': true},
        "updated": {'value': null, 'type': 'date', 'readonly': true},
        "key": {'value': null, 'type': 'text', 'required': true,},
        "txt": {'value': null, 'type': 'textarea', 'required': true,},
    }
}
