import {Component, OnInit} from '@angular/core';

export class ItemToDealGroup {
    data = {
        'id':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "itemToDealGroup";
    title = "Item To Deal Group";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        business: {'title': 'business', 'value': null, 'type': 'text', 'readonly': true},
        'price': {'title': 'price', 'value': null, 'type': 'float'},
        "item": {
            'showDefault': true, 'value': null,
            'filters': {'dealItem':true},
            'displayField': 'itemName', 'type': 'modelscroll',
            'modelscroll': 'Item', 'keyField': 'id', 'valueField': 'name',
        },
        // "group": {
        //     'showDefault': true, 'value': null,
        //     'displayField': 'dealName', 'type': 'modelscroll',
        //     'modelscroll': 'Deal', 'keyField': 'id', 'valueField': 'name',
        // },
    }
}
