import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Events, ModalController, NavController, PopoverController} from "@ionic/angular";
import {FieldDataObject} from "../../../../app/global-module/global-module.module";

@Component({
  selector: 'autofield',
  templateUrl: './autofield.component.html',
  styleUrls: ['./autofield.component.scss'],
})
export class AutofieldComponent implements OnInit {
    @ViewChild('input', null) input ;

    @Input()
    fieldkey:string;

    @Input()
    model:any;

    @Input()
    hideTitle:boolean;

    @Input()
    hideBox:boolean = false;

    //???
    @Input()
    ignoreCustomEditField:boolean = false;

    //???
    @Input()
    autoOpen:boolean = false;

    @Input()
    placeholder:boolean = false;

    //???
    @Input()
    tableOnPopup:boolean = false;

    @Input()
    readonly:boolean = false;

    @Input()
    navCtrl:NavController;

    //???
    @Input()
    modelScrollFilter:object;

    //???
    @Input()
    modelScrollAddParams:object;

    //???
    @Input()
    showFirstOption:boolean = true;

    @Input()
    prefix:string = 'autoFieldTablePrefix' + Math.floor((Math.random() * 100000) + 1);

    @Input()
    optionsConditions:object = {};

    @Input()
    onchange:any;

    @Input()
    onkeyup:any;

    @Input()
    value:string;

    @Input()
    background:string;

    @Input()
    showDisabled:boolean = false;

    @Input()
    textHeight:string;

    @Input()
    fieldsFilters:object;

    @Input()
    directCalendar:boolean=false;

    @Input()
    calendarOpen:boolean=false;

    @Input()
    hideMap:boolean=false;

    @Input()
    options:object = {};

    public fieldType:string;
    public fieldData: FieldDataObject = {'title':null, 'type': null, 'height':null, 'min':null, 'addParams':null, 'maxlength':30, 'bigarea':null};

    public calendarOptions:object;

    constructor(public modalCtrl:ModalController, public popoverCtrl:PopoverController, public events:Events) {
    }

    ngOnInit() {
        // console.log('auto field')
        // //console.log(this.model.dataFields)
        // console.log(this.fieldkey);
        //console.log(this.model.dataFields);

        this.fieldData = this.model.dataFields[this.fieldkey];
        this.fieldType = this.fieldData ? this.fieldData.type : null;

        if(this.fieldData && this.fieldData.type == 'date')
            this.setCalendarOptions()

        if (this.fieldData && this.fieldData.type == 'date' && this.autoOpen) {
            this.openCalendar(this.fieldkey);
        }

        if (!this.textHeight && this.fieldData && this.fieldData.height)
            this.textHeight = this.fieldData.height;

        if(this.fieldData)
            if(!this.modelScrollAddParams) this.modelScrollAddParams = this.fieldData.addParams;

        this.events.subscribe(this.prefix+'_setFocus', (prams)=>
        {
            if(!this.input) return;


            if(this.model.dataFields[this.fieldkey]['type'] == 'time')
                this.input.open();
            else
                this.input.setFocus();
        })
        // if(this.fieldData && this.fieldData.type == 'date' && !this.model.data[this.fieldkey])
        // {
        //   this.model.data[this.fieldkey] = new Date()
        // }
    }

    setCalendarOptions()
    {
        this.calendarOptions = {
            // defaultTitle: 'BASIC',
            from: this.fieldData.min ? this.fieldData.min : '2018/1/1',
            to: '2019/12/31',
            defaultScrollTo: this.model.data[this.fieldkey] ? this.model.data[this.fieldkey] : new Date(),
            defaultDate: this.model.data[this.fieldkey]
        };
    }

    openCalendar(fieldkey) {
        // var me = this;
        // //pickMode: 'range',
        //
        // //CalendarModalOptions
        //
        // let myCalendar = this.modalCtrl.create(CalendarModal, {
        //     options: this.calendarOptions
        //
        // });
        //
        // myCalendar.present();
        //
        // myCalendar.onDidDismiss((date:CalendarResult, type:string) => {
        //     if (date) {
        //         me.model.data[fieldkey] = moment(date.string).format('YYYY-MM-DD');
        //     }
        // });
    }

    floatkeyUp(event:any) {
        var MY_REGEXP = /^\s*(\-|\+)?(\d+|(\d*(\.\d*)))([eE][+-]?\d+)?\s*$/;
        let newValue = event.target.value;
        let regExp = new RegExp(MY_REGEXP);

        if (!regExp.test(newValue)) {
            event.target.value = newValue.slice(0, -1);
            this.model.data[this.fieldkey] = event.target.value
        }
    }

    numberMaxLength(e:any, maxlength, parseType = 'int') {
        if (maxlength === null)
            return;
        var value = e.target.value
        // value = parseInt(value)
        if (this.parse(value, parseType) < 0) {
            e.target.value = 0;
            this.model.data[this.fieldkey] = e.target.value
        }

        if (value.toString().length > maxlength) {
            var n = value.toString().length - maxlength
            value = value.slice(0, -n)
            // e.target.value = value;
            // this.model.data[this.fieldkey] = e.target.value
        }
    }

    changed()
    {
        if(this.onchange) this.onchange(this.model);
    }

    dokeyup(value)
    {
        // if(this.onkeyup) this.onkeyup['callback'](this.model, this.onkeyup['data']);
        // value = this.parse(value, parseType);
        // if (!value) value = 0;
        // this.model.data[this.fieldkey] = value
    }

    parse(value, parseType) {
        if (parseType == 'int') return parseInt(value)
        return parseFloat(value)
    }
}
