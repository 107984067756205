import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {IonicStorageModule} from "@ionic/storage";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpLoaderFactory} from "./global-module/global-module.module";
import {MainService} from "../core/services/MainService";
import {FilterProvider} from "../framework/providers/modelFilter";
import {ModelProvider} from "../framework/providers/modelProvider/ModelProvider";
import {UserData} from "../framework/providers/user-data";
import {ServerService} from "../framework/providers/server/server";
import {ModelClasses} from "../core/model/modelClasses";
import {AuthModel} from "../framework/providers/auth-service/authModel";
import {ModelToolsProvider} from "../framework/providers/model-tools/model-tools";
import {PageClasses} from "../core/model/pageClasses";
import {FrameWorkGlobals} from "../framework/providers/frameworkGlobals";
import {AuthService} from "../framework/providers/auth-service/auth-service";
import {OrderService} from "../core/services/OrderService";
import {BusinessService} from "../core/services/BusinessService";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
  ],
  providers: [
    MainService,
    OrderService,
    BusinessService,

    FilterProvider,
    ModelProvider,
    UserData,
    ServerService,
    HttpClient,
    ModelClasses,
    AuthModel,
    ModelToolsProvider,
    PageClasses,
    FrameWorkGlobals,
    AuthService,

    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
//
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18b/', '.json');
// }