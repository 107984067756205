export const SelectOptions = {
    "YES_NO_DONTKNOW_DEFAULT": "Yes",
    "YES_NO_DONTKNOW": {
        'yes': 'Yes',
        'no': 'No',
        'Dont know': "Don't know"
    },

    "HANDLE_SIDE": {
        'front':'tofront',
        'back':'toback'
    }
};