import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cart',
    pathMatch: 'full'
  },
  { path: 'cart', loadChildren: '../core/pages/cart/cart.module#CartPageModule' },
  { path: 'cart-details', loadChildren: '../core/pages/cart/pages/cart-details/cart-details.module#CartDetailsPageModule' },
  { path: 'cart-summary', loadChildren: '../core/pages/cart/pages/cart-summary/cart-summary.module#CartSummaryPageModule' },
  { path: 'cart-payment', loadChildren: '../core/pages/cart/pages/payment/payment.module#PaymentPageModule' },
  { path: 'order', loadChildren: '../core/pages/order/order.module#OrderPageModule' },
  { path: 'store', loadChildren: '../core/pages/store/store.module#StorePageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

