import { Component, OnInit } from '@angular/core';

export class DesktopStatFilter {
    data = {'filter': null, 'desktopStat': null};

    constructor(public data1:any) {
        this.data = data1;
    }

    frameworkModel = true;
    prefix = "desktopStatFilter";
    title = "DesktopStatFilter";
    defaultHeaders =
        ['filter', 'desktopStat'];
    dataFields = {
        "id": {'value': null, 'type': 'number', 'readonly': true},
        "created": {'value': null, 'type': 'date', 'readonly': true},
        "color": {'value': null, 'type': 'text'},
        "filter": {
            'value': null, 'type': 'modelscroll', 'displayField': 'filterName',
            'filterFunction': 'filterFilterFunction',
            'modelscroll': 'Filter', 'keyField': 'id', 'valueField': 'name', 'required': true,
        },
        "desktopStat": {
            'value': null, 'type': 'modelscroll', 'displayField': 'desktopStatName',
            'modelscroll': 'DesktopStat', 'keyField': 'id', 'valueField': 'name', 'readonly': true,
        },
    }

    filterFilterFunction(model, filters) {
        console.log("desktopStat:");
        console.log(model);
        filters['desktopStat'] = model.data.desktopStat;
        return filters;
    }
}


//getScrollFieldDisplay: function (desktopStatFilter, fieldName)
//{
//    var fields = {'name': desktopStatFilter.data.contentTypeName + ": " + desktopStatFilter.data.name,}
//    return fields[fieldName] ? fields[fieldName] : false;
//},
//getData: function (desktopStatFilterData)
//{
//    return this.data;
//},
//setData: function (desktopStatFilterData)
//{
//    angular.extend(this, {'data': desktopStatFilterData});
//},