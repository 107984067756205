import {Component, OnInit} from '@angular/core';

export class DealGeneralPropertyType {
    data = {
        'id':null,
        'name':null,
        'multiple':false
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "dealGeneralPropertyType";
    title = "Deal General Property Type";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text', 'required':true},
        multiple: {'title': 'multipleChoose', 'value': null, 'type': 'boolean'},
        amount: {'title': 'amount', 'value': null, 'type': 'number'},
    }
}
