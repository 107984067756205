import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {Storage} from '@ionic/storage';
import {UserData} from "../user-data";
import {User} from "../user-data";
import {ServerService} from "../server/server";
import {ModelProvider} from "../modelProvider/ModelProvider";
import {Puser} from "../../model/puser/puser";
import {AuthModel} from "./authModel";


@Injectable()
export class AuthService {
    constructor(private authModel: AuthModel,
                private server: ServerService,
                public userData: UserData,
                private modelProvider: ModelProvider) {
        //console.log('auth service!!')

    }

    public login(credentials) {
        var protocolName = 'api-token-auth';

        if (credentials.email === null || credentials.password === null) {
            return Observable.throw("Please insert credentials");
        } else {
            return Observable.create(observer => {
                // At this point make a request to your backend to make a real check!

                //var res = {'fname':'asaf', 'lname':'cohen',
                //    'puserId':1, 'id':2, 'email':'email@email.com',
                //    'token':'token'};

                this.server.post(null, protocolName + '/', credentials)
                    .then((res:any) => {
                        // this.userData.currentUser = this.userData;
                        this.userData.login({
                            'fname': res.fname,
                            'lname': res.lname,
                            'puserId': res.puserId,
                            'puserType': res.puserType,
                            'username': res.username,
                            'is_superuser': res.issuperuser,
                            'is_staff': res.isstaff,
                            'id': res.id,
                            'email': res.email,
                            'token': res.token
                        });


                        //var userData = this.userData.getUserdata();

                        //let access = res.token ? true : false; //(credentials.password === "pass" && credentials.email === "email");


                        //this.modelProvider.get('puser', userData.data.puserId, {}).then((res) => {
                        observer.next(true);
                        observer.complete();
                        //});

                    })
                    .catch(e => {
                        observer.next(false);
                        observer.complete();
                    });
            });
        }
    }

    public register(credentials) {
        if (credentials.email === null || credentials.password === null) {
            return Observable.throw("Please insert credentials");
        } else {
            // At this point store the credentials to your backend!
            return Observable.create(observer => {
                observer.next(true);
                observer.complete();
            });
        }
    }

    public getUserInfo(): User {
        return this.userData.currentUser;
    }

    public getAuthModel(): AuthModel {
        return this.authModel;
    }

    public hasLoggedIn(): Promise<boolean> {
        //console.log('baaaaaa cureent user?!?!');
        //console.log(this.authModel.currentUser);
        return this.userData.hasLoggedIn()
    }

    public logout() {
        //return Observable.create(observer => {
        //    this.currentUser = null;
        //    observer.next(true);
        //    observer.complete();
        //});

        this.userData.logout();

    }
}
