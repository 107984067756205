import {Component, Input, OnInit} from '@angular/core';
import {Item} from "../../../model/core/items/item";

@Component({
  selector: 'price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {

  @Input()
  item:Item;

  @Input()
  currencyCode:any = '₪';

  @Input()
  addTxt:string;

  @Input()
  value:any;

  @Input()
  showValue:any;

  constructor() {
  }

  ngOnInit(): void {
  }
}
