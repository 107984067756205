import {Component, Input} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {ModelClasses} from "../../../../core/model/modelClasses";
import {ModelProvider, ResInterface} from "../../../providers/modelProvider/ModelProvider";
import {PageClasses} from "../../../../core/model/pageClasses";
import {Filter} from "../../../model/general/filter/filter";
import {AlertController} from '@ionic/angular';
import {FilterField} from "../../../model/general/filter/filterField";
import {ModelToolsProvider} from "../../../providers/model-tools/model-tools";
import {FrameWorkGlobals} from "../../../providers/frameworkGlobals";
import {ColumnModel} from "../../../model/columnModel";
import {FilterProvider} from "../../../providers/modelFilter";
import {ModelDetailsPage} from "../../../pages/model-details/model-details.page";
import {Events} from "@ionic/angular";


interface SettingsObject {
    'displayType': string,
    'showInputFilter': boolean
}

@Component({
    selector: 'applist',
    templateUrl: 'app-list.component.html'
})
export class ApplistComponent {
    @Input()
    models: any = [];

    @Input()
    newModels: any = [];

    @Input()
    modelName: string;

    @Input()
    addParams: object;

    @Input()
    addInRow: boolean = true;

    @Input()
    cantEdit: boolean = false;

    @Input()
    addFilters: object;

    @Input()
    defaultFilters: string;

    @Input()
    fieldsFilters: object;

    @Input()
    showHeader: boolean = true;

    @Input()
    height: number = 600;

    @Input()
    displayType: string = 'none';

    @Input()
    defaultHeaders: any;

    @Input()
    shortHeaders: boolean = false;

    @Input()
    showPartsNoneDisplay: boolean = false; // use only when 'displayType' in 'none'

    @Input()
    partsDisplay: any = ['all'];

    @Input()
    partsDisplayPos: string = 'top';

    @Input()
    tableMode: boolean = true;

    @Input()
    showCheckBoxes: boolean = true;

    @Input()
    showKeys: boolean = true;

    @Input()
    eventOnClick: string;

    @Input()
    visibleBtn: object = {'active': false, 'visible': true};

    @Input()
    navCtrl: any;

    @Input()
    addPageClass: any;

    @Input()
    options: object = {};

    @Input()
    public showNumberCol = true;
    @Input()
    public showEye = true;

    @Input()
    public prefix;

    public columnModel: ColumnModel;

    public isScrollY = true;
    public modelFilter: any;
    public filters;
    filterKeys: any;
    public modelPerPage = 20;
    public currentOffset;
    public modelCount;

    public fieldName = 'fieldName';
    public model = 'model';
    public modelClass;
    public modelIns;

    public modelKeys: any;
    public editAbleModelKeys: any;
    public currentHeaderIndex: number;

    //public headersCount:number = 58;

    @Input()
    header: boolean = true;

    @Input()
    showCard: boolean = true;

    @Input()
    checked: object = {};

    showCardData: object = {};

    @Input()
    public checkedData: object = {};

    @Input()
    public checkedDataArr: any = [];

    lat: number = 51.678418;
    lng: number = 7.809007;

    settings: SettingsObject = {'displayType': null, 'showInputFilter': false};

    constructor(public events: Events,
                private alertCtrl: AlertController,
                private modelProvider: ModelProvider,
                private modelTools: ModelToolsProvider,
                private pageClasses: PageClasses,
                private modelClasses: ModelClasses,
                public popoverCtrl: PopoverController,
                public frameWorkGlobals: FrameWorkGlobals,
                public tableFilterProvider: FilterProvider) {

        this.tableFilterProvider = new FilterProvider();
    }

    ngOnInit() {
        if (!this.prefix)
            this.prefix = 'table_' + this.modelName;

        this.settings['displayType'] = this.displayType ? this.displayType : 'table';

        this.currentHeaderIndex = 0;
        this.modelClass = this.modelClasses.getClass(this.modelName);

        if (!this.modelClass) {
            console.log('modelclass load error: ' + this.modelName);
        }

        this.modelIns = new this.modelClass({});

        this.modelFilter = new Filter({'contentTypeName': this.modelIns.prefix});

        this.tableFilterProvider.setPrefix(this.prefix);
        this.filters = this.tableFilterProvider.getFilters();

        this.setHeaders();

        if (this.defaultFilters)
            this.tableFilterProvider.addFilters(this.defaultFilters);

        if (this.addPageClass)
            this.addInRow = false;

        if (!this.checked) this.checked = {};
        if (!this.checkedData) this.checkedData = {};

        this.listenCheckedCallback();
    }

    ngAfterViewInit() {
        this.loadModels();
    }

    private loadModels() {
        let prefix = this.prefix;

        let filters = this.tableFilterProvider.getFilters();

        this.currentOffset = filters['offset'];

        //this.appLoadingControllerProvider.presentLoadingDefault();

        let me = this;
        setTimeout(function () {
            me.options['showLoadAnim'] = true;
        }, 100);

        // console.log("LISTT - "+this.modelName)
        // console.log(filters)
        this.modelProvider.list(this.modelIns.prefix, filters).then((data: ResInterface) => {

            let models = data.models;
            for(let k in models)
                this.models.push(models);

            let count = data.count;

            //spinner issue..
            let me = this;
            setTimeout(function () {
                me.options['showLoadAnim'] = false;
            }, 1000);

            //this.appLoadingControllerProvider.stopLoadingGen();
            this.modelCount = count;

            this.options['modelCount'] = this.modelCount;
            this.options['modelPerPage'] = this.modelPerPage;
            //if (this.models)
            //{

            this.models.splice(0, this.models.length);

            for (var key in models) {
                this.models.push(models[key]);

                if (!this.checked[models[key].data.id])
                    this.checked[models[key].data.id] = false;
            }
            //}
            //else {
            //    this.models = models;
            //}

            var filters = this.tableFilterProvider.getFilters();

            //update rest of components.
            //console.log('fire: '+this.prefix + '_modelUpdated')
            // this.events.publish(this.prefix + '_modelUpdated', {
            //     'modelsCount': count,
            //     'models': this.models,
            //     'filters': filters
            // });

            this.events.publish(this.prefix + '_modelUpdated', {
                'modelsCount': count,
                'models': this.models,
                'filters': filters
            });

            this.events.publish(this.modelName + '_modelNameUpdated', {
                'modelsCount': count,
                'models': this.models,
                'filters': filters
            });

            if (this.displayType == 'map')
                this.events.publish('initMap', {'models': this.models});

            this.fixHeight();
        });
    }

    public openModel(modelId, model) {
        var params = {
            modelId: modelId,
            model: model,
            modelName: this.modelName
        };
        params['navCtrl'] = this.navCtrl;

        if (!this.pageClasses.getPageClass(this.modelName)) {
            params['pageClass'] = ModelDetailsPage;
        } else {
            params['pageClass'] = this.pageClasses.getPageClass(this.modelName);
        }

        this.navCtrl.push(params['pageClass'], params);
    }

    //field actions
    public openFieldDetailsPopup(fieldkey) {
        // let popover = this.popoverCtrl.create(TableFieldOptionsPopupComponent,
        //     {
        //         fieldkey: fieldkey,
        //         modelName: this.modelName,
        //         checkedData: this.checked,
        //         tablePrefix: this.prefix
        //     });
        // popover.present();
    }

    //subscribe to get in to instance.
    public listenCheckedCallback() {

        // if(this.serverService.getEvent(this.prefix))
        //   return;
        //
        // this.serverService.setEvent(this.prefix)

        this.events.subscribe(this.modelName + '_setChecked', (params) => {
            this.updateChecked(params.model, params.checked);
        });

        //show card event
        if (this.showCard)
            this.events.subscribe(this.prefix + '_showCard', (params) => {

                if (!params.extraData)
                    params.extraData = {};
                params.extraData['checked'] = this.checked;

                this.showCardData = {
                    'model': params.model,
                    'extraData': params.extraData,
                    'navCtrl': this.navCtrl
                };

                //this.tableFilterProvider.addFilter('id', params.model.data.id);
                //this.updateFilterModel();
                //this.loadModels();
            });

        //show card event
        this.events.subscribe(this.prefix + '_hideCard', (params) => {
            this.showCardData = {};
        });


        //add filters
        this.events.subscribe(this.prefix + '_updateFilter', (filters) => {
            if (filters['clearFilters'])
                this.tableFilterProvider.clearFilters();

            for (var key in filters)
                this.tableFilterProvider.addFilter(key, filters[key]);

            this.updateFilterModel();
            this.loadModels();
        });

        //auto refresh
        this.events.subscribe(this.modelName + '_updateFilter', (params) => {
            this.updateFilterModel();
            this.loadModels();
        });

        this.events.subscribe(this.prefix + '_updateAddParams', (params) => {
            if (params['clearFilters']) {
                for (var key in this.addParams)
                    delete this.addParams[key];
            }

            for (var key in params)
                this.addParams[key] = params[key];
        });

        //on add in row click
        // this.events.subscribe(this.prefix + '_cancelNewCreatedModel', (params) => {
        // 	this.cancelNewModel(model);
        // });
        this.events.subscribe(this.prefix + '_addInRow', (params) => {
            var model: any = new this.modelClass({});
            this.newModels.push(model);
            //this.models.unshift(model);
            this.fixHeight();
        });


        this.events.subscribe(this.prefix + '_saveNewCreatedModel', (params) => {
            this.saveNewCreatedModel()
        });

        this.events.subscribe(this.prefix + '_cancelNewModels', (params) => {
            this.cancelNewModels()
        });

        this.events.subscribe(this.prefix + '_updateDisplayType', (params) => {
            this.setDisplayType(params['type'])
        });

        this.events.subscribe(this.modelName + '_updateDisplayType', (params) => {
            this.setDisplayType(params['type'])
        });
    }


    private setHeaders() {

        //if entered the page before app is ready.
        if (!this.frameWorkGlobals.columnsModels) {
            return;
        }

        // var columnModel:ColumnModel = this.frameWorkGlobals.getColumnModel(this.modelIns.prefix, this.modelIns.prefix);
        this.columnModel = columnModel;

        var columnModel: ColumnModel;

        var dataFields = this.modelIns.dataFields;

        // defaultHeaders from input?
        if (this.defaultHeaders) {
            //this.log('1')
            this.modelKeys = this.defaultHeaders;
        } else if (columnModel) {
            this.columnModel = columnModel;
            this.modelKeys = columnModel.data.fields.split(',');
        } else if (this.modelIns.shortHeaders) {
            //this.log('2')
            this.modelKeys = this.modelIns.shortHeaders;
        } else if (this.modelIns.defaultHeaders) {
            //this.log('3')
            this.modelKeys = this.modelIns.defaultHeaders;
        } else {
            //this.log('4')
            this.modelKeys = [];

            for (var key in dataFields) {
                if (dataFields[key] && dataFields[key].type != 'oneToMany') {
                    this.modelKeys.push(key);
                }
            }
        }

        // console.log(this.modelKeys);

        this.editAbleModelKeys = [];
        for (var key in this.modelKeys) {
            if (dataFields[this.modelKeys[key]] && !dataFields[this.modelKeys[key]].readonly
                && !dataFields[this.modelKeys[key]].oneToOne) {
                this.editAbleModelKeys.push(this.modelKeys[key]);
            }
        }

        //
        //create column model if first time to open table.
        //console.log('this column Model')
        //console.log(this.columnModel)

        if (!this.columnModel) {
            //console.log('create column model!!!! ' + this.modelIns.prefix);
            this.columnModel = this.frameWorkGlobals.createModelColumn(this.modelKeys, this.modelIns, this.modelIns.prefix);
        }
    }

    setDisplayType(type) {
        this.settings['displayType'] = '';
        this.setHeaders();

        var me = this;
        setTimeout(function () {
            me.settings['displayType'] = type;
        }, 1);
    }

    //get filters from filter model.
    public updateFilterModel() {
        this.filterKeys = [];
        for (var key in this.modelFilter.data) {

            if (this.modelFilter.data[key] !== null) {
                if (this.modelFilter.dataFields[key] && !this.modelFilter.dataFields[key].notafilter) {
                    this.tableFilterProvider.addFilter('sfilter|' + key + '|' + this.modelFilter.data[key + '_filtertype'], this.modelFilter.data[key]);
                    this.filterKeys.push(key);
                }
            }
        }
    }

    //save new filter from keys filterd.
    //enter name or cancel.
    public saveFilter() {
        let alert = this.alertCtrl.create({
            header: 'Filter Name',
            inputs: [
                {
                    name: 'name',
                    placeholder: 'Filter Name'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: data => {
                    }
                },
                {
                    text: 'Save',
                    handler: data => {

                        var filter = new Filter({});
                        filter.data.name = data.name;
                        this.modelProvider.create(filter, {'contentTypeName': this.modelIns.prefix})
                            .then((res) => {

                                var fields = [];

                                for (var key in this.filterKeys) {
                                    var filterField = new FilterField({});
                                    filterField.data.filter = filter.data.id;

                                    filterField.data.filterType = this.modelFilter.data[this.filterKeys[key] + '_filtertype'];
                                    filterField.data.value = this.modelFilter.data[this.filterKeys[key]];
                                    filterField.data.fieldName = this.filterKeys[key];
                                    fields.push(filterField);
                                }

                                this.modelProvider.createMultiple(fields[0], fields)
                                    .then((res) => {

                                    });
                            });
                    }
                }
            ]
        });

        //tmpremove
        // alert.present();
    }

    public removeFilter(filterKey) {
        var key = 'sfilter|' + filterKey + '|' + this.modelFilter.data[filterKey + '_filtertype'];
        this.tableFilterProvider.removeFilter(key);
        this.modelFilter.data[filterKey] = null;

        this.updateFilterModel();
        this.loadModels();
    }

    public getFilterName(filterKey) {
        this.filters = this.tableFilterProvider.getFilters();
        return filterKey.split('|')[1] + " " + filterKey.split('|')[2]
    }

    public cancelNewModel(model) {
        // var model = params.model;
        this.newModels.splice(this.newModels.indexOf(model), 1);
        // this.models.splice(this.models.indexOf(model), 1);
    }

    public cancelNewModels() {
        for (var k = 0; k < this.models.length; k++) {
            if (!this.models[k].data.id)
                this.models.splice(k, 1);
        }
        this.newModels.length = 0;
    }

    //get new model with info in it. and add it to addedparams and save..
    public saveNewCreatedModel() {
        for (var key in this.newModels) {
            for (var d in this.addParams) {
                this.newModels[key].data[d] = this.addParams[d];
            }
        }

        this.modelProvider.createMultiple(this.newModels[0], this.newModels, this.addFilters, this.prefix).then((res) => {

            //for (var key in this.newModels[0].dataFields)
            //{
            //    if (this.newModels[0].dataFields[key].oneToOne)
            //    {
            //        for (var key in this.newModels) {
            //
            //        }
            //    }
            //}


            this.newModels = [];
        });
    }

    //on check click
    public updateChecked(model, checked) {
        if (this.checked[model.data.id] === true && checked !== true) {
            this.checked[model.data.id] = false;
            this.checkedData[model.data.id] = null;
        } else if (checked !== false) {
            this.checked[model.data.id] = true;
            this.checkedData[model.data.id] = model;
        }


        this.checkedDataArr.splice(0, this.checkedDataArr.length)
        for (var key in this.checkedData) {
            if (this.checkedData[key])
                this.checkedDataArr.push(this.checkedData[key])
        }
    }

    public getModelTitle(model) {
        if (model.getModelTitle) {
            return model.getModelTitle(model);
        } else {
            return {'title': model.data.id, 'bgcolor': '#fff'};
        }
    }

    fixHeight() {

        //var boxHeight = this.displayType.indexOf('box') != -1 && this.modelIns.boxHeight ? this.modelIns.boxHeight : 40;
        //if (!boxHeight)
        //    boxHeight = 40;
        //
        //var prefix = this.prefix;
        ////need scroll?
        //this.isScrollY = true;
        //var rowHeight = 90 + (this.models.length * boxHeight);
        ////if (this.models.length > 10) {
        ////    this.isScrollY = true;
        ////}
        ////fix table height
        //var me = this;
        //setTimeout(function () {
        //    var scrollH:any;
        //    scrollH = document.getElementsByClassName('model-scroll_' + prefix)[0];
        //    if (scrollH)
        //    //scrollH.style.height = (rowHeight > 600 ? 600 : rowHeight) + 'px';
        //        scrollH.style['min-height'] = (rowHeight > 600 ? 600 : rowHeight) + 'px';
        //}, 1300);
    }

    ngOnDestroy() {
        this.destroyEvents()
    }

    destroyEvents() {
        // console.log('unsubscribe!! ' + this.modelName)
        this.events.unsubscribe(this.modelName + '_setChecked');
        this.events.unsubscribe(this.prefix + '_showCard');
        this.events.unsubscribe(this.prefix + '_hideCard');
        this.events.unsubscribe(this.prefix + '_updateFilter');
        this.events.unsubscribe(this.prefix + '_cancelNewCreatedModel');
        this.events.unsubscribe(this.prefix + '_addInRow');
        this.events.unsubscribe(this.modelName + '_updateFilter');

        // this.serverService.setEvent(this.prefix, false)
    }

    public isshow(comName) {
        if (this.partsDisplay.indexOf('all') !== -1)
            return true;

        if (this.partsDisplay.indexOf(comName) !== -1)
            return true;

        return false;
    }

    public openEditFilterPopup() {
        // this.navCtrl.push(CreateFilterComponent,
        //     {
        //         model: this.modelFilter,
        //         keys: ['fields'],
        //         navCtrl: this.navCtrl,
        //         extraData: {}
        //     });
        //
        // //popover.present();
    }
}
