import { Component, OnInit } from '@angular/core';
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
})
export class NewOrderComponent implements OnInit {

  constructor(public popoverCtrl:PopoverController) { }

  ngOnInit() {}

  close()
  {
    this.popoverCtrl.dismiss();
  }
}
