import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {MainService} from "../core/services/MainService";
import {ActivatedRoute} from "@angular/router";
import {BusinessService} from "../core/services/BusinessService";
import {Subscription} from "rxjs";
import {OrderService} from "../core/services/OrderService";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss',]
})

export class AppComponent {
    ready: boolean = false;

    subscription: Subscription;

    public appPages = [
        {
            title: 'Home',
            url: '/home',
            icon: 'home'
        },
        {
            title: 'List',
            url: '/list',
            icon: 'list'
        }
    ];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private mainService: MainService,
        private businessService: BusinessService,
        private orderService: OrderService,
        private route: ActivatedRoute,
    ) {


        let me = this;
        setTimeout(function () {
            me.ready = true;
        }, 2500)

        this.initializeApp();

        this.mainService.start()


        // this.subscription = this.route.queryParams.subscribe(params => {
        let url = window.location.href;
        let params = this.getParams(url)
        let prefix = params['pref'];

        if (!prefix) {

            let parts = url.split('.');
            let urlpref = parts[0].replace('http://', '').replace('https://', '');

            if (urlpref != 'shaps' && urlpref != 'www')
                prefix = urlpref;
        }

        if (prefix) {
            this.businessService.setBisByPrefix(prefix);

            this.orderService.setCartPrefix(params['cart']);

            let me = this;
            setTimeout(function () {
                me.unsubscribe()
            }, 100)
        }
        // });
    }

    getParams(url) {
        let pr = url.split('?');
        if (pr.length == 1) return {};
        let parts = pr[1].split('&');
        let params = {};
        for (let p in parts) {
            let ps = parts[p].split('=');
            params[ps[0]] = ps[1];
        }
        return params
    }

    unsubscribe() {
        // this.subscription.unsubscribe()
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
}
