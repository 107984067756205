import { Component, OnInit } from '@angular/core';

export class ModelToImage {
	data = {
		'id':null,
	};

	constructor(public data1: any) {
		this.data = data1;
	}

	title = "image";
	prefix = "modelToImage";
	icon = "glyphicon glyphicon-book";
	showDefaultsCols = true;
	dataFields = {
		"id": null,
		"created": null,
		"file": null,
		"title": null,
		"typePrefix": null,
		"objectId": null,
		"business": null,
		"contentType": null,
	}
};
