import { Component, OnInit } from '@angular/core';

export class Puser {
	data = {};

	constructor(public data1: any) {
		this.data = data1;
	}
	defaultHeaders = ["fname", "lname", "username", "password", "email", "business", "puserType"]
	prefix = "puser";
	icon = "contact";
	dataFields = {
		"id": { 'title': 'ID', 'value': null, 'type': 'number', 'readonly': true },
		"created": { 'value': null, 'type': 'date', 'readonly': true },
		"updated": { 'value': null, 'type': 'date', 'readonly': true },

		"fname": { 'value': null, 'type': 'text', 'required': true },
		"lname": { 'value': null, 'type': 'text', 'required': true },
		"username": { 'value': null, 'type': 'text', 'required': true },
		"password": { 'value': null, 'type': 'text', 'primary': true },
		// "is_staff": { 'value': null, 'type': 'boolean', 'readonly': true },
		// "is_superuser": { 'value': null, 'type': 'boolean', 'readonly': true },
		"email": { 'value': null, 'type': 'email', 'required': true },

		"business": {
			'showDefault': true, 'value': null, 'type': 'modelscroll',
			'modelscroll': 'Business', 'required': true,
			'displayField': 'businessName', 'keyField': 'id', 'valueField': 'name'
		},

		"user": {
			'showDefault': true, 'value': null, 'type': 'modelscroll',
			'modelscroll': 'User', 'readonly': true,
			'displayField': 'userName', 'keyField': 'id', 'valueField': 'name'
		},

		"puserType": {
			'showDefault': true, 'value': null, 'type': 'modelscroll',
			'modelscroll': 'PuserType', 'required': true,
			'displayField': 'puserTypeName', 'keyField': 'id', 'valueField': 'name'
		},
	}
}
