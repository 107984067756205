import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../providers/modelProvider/ModelProvider";
import {ModelToolsProvider} from "../../../providers/model-tools/model-tools";
import {FieldTableEditPage} from "../../../pages/field-table-edit/field-table-edit.page";
import {FieldEditPage} from "../../../pages/field-edit/field-edit.page";
import {FieldDataObject} from "../../../../app/global-module/global-module.module";

@Component({
  selector: 'displayfield',
  templateUrl: './displayfield.component.html',
  styleUrls: ['./displayfield.component.scss'],
})
export class DisplayfieldComponent implements OnInit {
    @Input()
    fieldkey: string = '';

    @Input()
    model: any;

    @Input()
    lazyModel: boolean = false;

    @Input()
    format: string;

    @Input()
    emptyBox: boolean = false;

    @Input()
    titleOnEmpty: boolean = false;

    @Input()
    customTitleOnEmpty: string;

    @Input()
    hideOnEmpty: boolean = true;

    @Input()
    showColors: boolean = true;

    //on edit - model would not make an update to server.

    @Input()
    editFields: any;

    @Input()
    tablePrefix: string = null;

    //throw an event on edit field from edit page.
    @Input()
    onEditEventPrefix: string;

    @Input()
    editable: boolean = true;

    @Input()
    fixText: boolean = false;

    @Input()
    updateFields: any = [];

    @Input()
    addParams: object;

    @Input()
    options: object;

    editMode: boolean = false;

    public fieldType: string;
    public fieldData: FieldDataObject;
    public textVal: string;

    @Input()
    navCtrl: any;

    constructor(public popoverCtrl: PopoverController, public modelProvider: ModelProvider,
        public modelTools: ModelToolsProvider) {
        //console.log('Hello DisplayfieldComponent Component');
    }

    ngOnInit() {
        if (this.model.dataFields[this.fieldkey]) {
            this.fieldData = this.model.dataFields[this.fieldkey];
            this.fieldType = this.model.dataFields[this.fieldkey].type;
            this.textVal = this.model.data[this.fieldData.displayField ? this.fieldData.displayField : this.fieldkey];
        }
    }

    public cancelEdit() {
        this.editMode = false;
    }

    public saveField() {
        var me = this;
        this.modelProvider.update(this.model, {}).then((res) => {
            this.cancelEdit();
        });
    }

    public editPopover() {
        //edit model specific fileds - I think.
        // if (this.editFields) {
        //     this.editFieldsPopover();
        //     return
        // }
        //
        // //One to One
        // if (this.fieldData.oneToOne) {
        //     var data = {
        //         'model': this.model,
        //         'extModel': this.model.data[this.fieldData.modelDataKey + 'Data'],
        //         'fieldkey': this.fieldkey,
        //         'fieldData': this.model.data[this.fieldData.modelDataKey + 'Data'].dataFields[this.fieldData.extFieldName],
        //         'fieldType': this.model.data[this.fieldData.modelDataKey + 'Data'].dataFields[this.fieldData.extFieldName].type,
        //         'extFieldkey': this.fieldData.extFieldName,
        //         'onEditEventPrefix': this.onEditEventPrefix
        //     };
        //
        //     let popover = this.popoverCtrl.create(FieldEditPage, data);
        //     popover.present();
        // }
        // else {
        //
        //     //edit on table?
        //
        //     // this.editMode = true;
        //     if (this.fieldData['editMode'] == 'table' && this.fieldData.type == 'modelscroll') {
        //         let popover = this.popoverCtrl.create(FieldTableEditPage, {
        //             model: this.model,
        //             fieldData: this.fieldData,
        //             fieldType: this.fieldType,
        //             fieldkey: this.fieldkey,
        //             tablePrefix: this.tablePrefix,
        //             displayField: this.fieldData.displayfield,
        //             onEditEventPrefix: this.onEditEventPrefix
        //         }, { 'cssClass': 'lg-popover' });
        //         popover.present();
        //     }
        //     else {
        //
        //         //regular edit popup
        //         let popover = this.popoverCtrl.create(FieldEditPage, {
        //             'model': this.model,
        //             'fieldData': this.fieldData,
        //             'fieldType': this.fieldType,
        //             'fieldkey': this.fieldkey,
        //             'tablePrefix': this.tablePrefix,
        //             'onEditEventPrefix': this.onEditEventPrefix,
        //             'lazyModel': this.lazyModel,
        //         });
        //         popover.present();
        //     }
        // }
    }


    public editFieldsPopover() {
        this.modelTools.openEditPopup(this.popoverCtrl, this.model, this.editFields);
    }

    public showEmptyData()
    {
        if (!this.fieldData) return false;

        if (this.fieldData.type == 'addressbook' && this.fieldData['data'])
          if(this.model.data[this.fieldData['data']]) return false;

        if ((
             (
                (
                    this.model.data[this.fieldkey]===null
                    || this.model.data[this.fieldkey]===''

                    || (this.model.data[this.fieldkey]===0
                        && this.fieldData.type != 'float'
                        && this.fieldData.type != 'number')
                )
                 && ['customField', 'images'].indexOf(this.fieldData.type)==-1
             )

             || this.fieldData.oneToOne &&
             (this.model.data[this.fieldkey+'Data'].data[this.fieldData.extFieldName] === ''
             ||  this.model.data[this.fieldkey+'Data'].data[this.fieldData.extFieldName] === null))
            && !this.fieldData.selfTemplate
        )
            return true;

        return false;
    }


}
