import {Component, Input, OnInit} from '@angular/core';
import {Events} from "@ionic/angular";

@Component({
  selector: 'paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
})
export class PagingComponent implements OnInit {
    public page: number;
    public pageNumbers: Array<any>;

    @Input()
    modelPerPage: number;

    @Input()
    modelCount: number;

    @Input()
    prefix: string;

    offset: number;
    limit: number;

    public pagesCount: number;
    public prePageIndex: number;
    public nextPageIndex: number;

    constructor(public events: Events) {
        //console.log('Hello PagingComponent Component');
        //this.text = 'Hello World';

        this.offset = 0;
    }

    ngOnInit() {
        this.setPages(this.modelCount);

        this.events.subscribe(this.prefix + '_modelUpdated', (params) => {
            this.modelPerPage = params['filters']['limit'];
            this.offset = params['filters']['offset'];
            this.setPages(params['modelsCount']);
        });
    }

    public updateLimit(limit) {
        this.page = 1;
        this.limit = limit;
        this.refreshPage(1);
    }

    public refreshPage(newPageIndex) {
        this.page = parseInt(newPageIndex);

        this.nextPageIndex = this.page + 1;
        this.prePageIndex = this.page - 1;

        this.events.publish(this.prefix + '_updateFilter', { 'offset': (this.page - 1) * this.modelPerPage });
    }

    public refresh() {
        this.refreshPage(1);
    }

    public setPages(modelCount) {
        this.modelCount = modelCount;
        this.page = (this.offset / this.modelPerPage) + 1;

        this.pagesCount = Math.ceil(this.modelCount / this.modelPerPage);

        var pages = [];
        for (var pageId = 0; pageId < this.pagesCount; pageId++) {
            if (pageId == 1 || (pageId > this.page - 2 && pageId < this.page + 3 && pageId != 1) || pageId == (this.pagesCount))
                pages.push(pageId + 1);
        }
        this.pageNumbers = pages;

        this.nextPageIndex = this.page + 1;
        this.prePageIndex = this.page - 1;
    }

}
