import {Injectable} from '@angular/core';

@Injectable()
export class FilterProvider {
    public prefix: string;
    public filters: object;

    constructor() {
        this.filters = {'limit': 20, 'offset': 0}
    }

    ngOnInit() {
    }

    public setPrefix(prefix) {
        this.prefix = prefix;
    }

    public addFilters(filters) {
        for (var filterKey in filters)
            this.filters[filterKey] = filters[filterKey];
    }

    public clearFilters() {
        this.filters = {};
    }

    public addFilter(filterKey, filterValue) {
        this.filters[filterKey] = filterValue;
    }

    public removeFilter(filterKey) {
        delete this.filters[filterKey];
    }

    public getFilters() {
        return this.filters;
    }

}
