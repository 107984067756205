import {Component, OnInit} from '@angular/core';

export class Item {
    data = {
        id: null,
        name: null,
        code: null,
        price: null,
        dealPropertyTypes:null,
        props:null,
        sectionId:null,
        groups:null,
        dealItem:null,
        images:null,
        comments:null,
        description:null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "item";
    title = "item";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        'name': {'title': 'name', 'value': null, 'type': 'text', 'required': true},
        'price': {'title': 'price', 'value': null, 'type': 'float', 'nis': true, 'maxlength': 5},
        "type": {
            'showDefault': true, 'value': null,
            'filters': {'contentTypeName': 'item', 'fieldName': 'type'},
            'displayField': 'typeName', 'type': 'modelscroll',
            'modelscroll': 'ModelStatus', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        "sectionId": {
            'title': 'category',
            'showDefault': true, 'value': null,
            'filters': {'ordering': 'order'},
            'displayField': 'sectionName', 'type': 'modelscroll',
            'modelscroll': 'Section', 'keyField': 'id', 'valueField': 'name',
            //'multipleSelect':true,
        },
        'handleSide': {
            'title': 'kitchen',
            'showDefault': true, 'value': null,
            'displayField': 'handleSide', 'type': 'optionsscroll', 'optionsscroll':'HANDLE_SIDE',
        },
        'description': {'title': 'description', 'value': null, 'type': 'textarea', 'maxlength': 3000},
        'comments': {'title': 'comments', 'value': null, 'type': 'textarea'},
    }
}