import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Events} from "@ionic/angular";
import {AppConfig} from "../../../../core/conf/main";

@Component({
  selector: 'valid-form',
  templateUrl: './valid-form.component.html',
  styleUrls: ['./valid-form.component.scss'],
})
export class ValidFormComponent implements OnInit {
    @Input()
    public fields: any;

    @Input()
    public options: object;

    @Input()
    public fieldsOptions: object = {};

    @Input()
    public model: any;

    @Input()
    public allRequired: boolean = false;

    @Input()
    public readonly: boolean = false;

    @Input()
    public addTitleText: string;

    public prefix = 'validForm_' + +Math.floor(Math.random() * Math.floor(1000000));

    constructor(public events: Events, private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (!this.options) this.options = {
            'isSent': false,
            'valid': false,
        }

        this.options['prefix'] = this.prefix;

        this.events.subscribe(this.prefix, (params) => {
            this.options['isSent'] = true;
            this.checkValid();
        })

        this.changeDetector.detectChanges();
    }

    ngOnDestroy() {
    }

    isValidField(key) {
        if (!this.allRequired && !this.model.dataFields[key].required) return true;
        return this.model.data[key] != null && this.model.data[key] != '';
    }

    checkValid() {
        var valid = true;
        for (var key in this.fields)
            if (!this.isValidField(this.fields[key])) valid = false;
        this.options['valid'] = valid;
    }

    changed() {
        this.events.publish('onTouch');
    }

    setFocus(key) {
        this.events.publish(this.prefix + '_' + key + '_setFocus');
    }

    setTextOption(key, opt)
    {
        if(!this.model.data[key] || !this.model.dataFields[key]['textOptionsMultiple'])
        {
            this.model.data[key] = opt;
            return;
        }

        if(this.indexOf(this.model.data[key],opt) != -1)
        {
            var exists = this.model.data[key].split(',')
            var values = []
            for(var k in exists)
            {
                if(exists[k] != opt)
                    values.push(exists[k])
            }
        }
        else
        {
            let values = this.model.data[key].split(',')
            values.push(opt)

        }

        this.model.data[key] = values.join();
    }

    indexOf(str, key)
    {
        if(!str) return -1
        return str.indexOf(key);
    }

    getDir()
    {
        return AppConfig.dir;
    }
}
