import {Component, Input, OnInit} from '@angular/core';
import {Events} from "@ionic/angular";
import {BusinessService} from "../../services/BusinessService";
import {BusinessImages} from "../../../framework/model/core/business/businessImages";

@Component({
  selector: 'app-background',
  templateUrl: './app-background.component.html',
  styleUrls: ['./app-background.component.scss'],
})
export class AppBackgroundComponent implements OnInit {

    businessImages: BusinessImages;
    @Input()
    public blank: boolean = false;

    @Input()
    public disableBackground: boolean = false;

    @Input()
    public global: boolean = false;

    @Input()
    public main: boolean = false;

    @Input()
    public inital: boolean = false;

    public loading: boolean = false;
    public showBackButton: boolean = false;
    public loadingOffline: boolean = false;

    constructor(public events: Events, public businessService: BusinessService) {

    }

    ngOnInit() {
        this.events.subscribe('businessImagesSets', (params) => {
            this.businessImages = params['data']
        });
        this.getImages();

        this.events.subscribe('setLoadingOn', (params) => {
            this.setLoadingOn()
        });

        this.events.subscribe('setLoadingOff', (params) => {
            this.setLoadingOff()
        });

        this.events.subscribe('setLoadingOffline', (params) => {
            this.loadingOffline = true;
        });

        var me = this;
        setInterval(function () {
            me.inital = false;
        }, 2000);
    }

    getImages() {
        this.businessImages = this.businessService.getImages()
    }

    setLoadingOn() {
        this.loadingOffline = false;

        this.loading = true;
        this.interval()
    }

    setLoadingOff() {
        var me = this;
        this.showBackButton = false;
        this.loadingOffline = false;
        setTimeout(function () {
            me.loading = false;
        })
    }

    interval() {
        var me = this;
        setInterval(function () {
            if (me.loading) me.showBackButton = true;
        }, 30000);
    }

    tryAgain()
    {
        this.setLoadingOff();
        this.events.publish('goToRoot');
    }
}
