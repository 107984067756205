import {Component, OnInit, ViewChild} from '@angular/core';
import {Events, IonSlides, NavController, PopoverController} from "@ionic/angular";
import {MainService} from "../../../../services/MainService";
import {OrderService} from "../../../../services/OrderService";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {ActivatedRoute} from "@angular/router";
import {Address} from "../../../../../framework/model/core/address/address";
import {AppUser} from "../../../../../framework/model/core/appuser/appuser";
import {Order} from "../../../../../framework/model/core/order/order";

@Component({
  selector: 'order-address',
  templateUrl: './order-address.component.html',
  styleUrls: ['./order-address.component.scss'],
})
export class OrderAddressComponent implements OnInit {

    order: Order;
    address: Address;
    appuser: AppUser;

    addresses: Array<Address> = [];
    addressPrefix: string = 'addressPrefix';
    choosedAddress: Address;
    choosedAddressId: any = -1;

    options: object = {};
    formOptions: object = {};

    notOnRadius: boolean = false;

    @ViewChild('slides', null) slides: IonSlides;

    constructor(public events: Events, public navCtrl: NavController, public mainService: MainService, public orderService: OrderService,
                public modelProvider: ModelProvider, public route: ActivatedRoute, public popoverCtrl:PopoverController) {

        this.route.queryParams.subscribe(params => {
            this.order = params['order']
        });

        this.appuser = this.orderService.getAppUser();

        this.address = new Address({});
    }

    ngOnInit() {

        this.events.subscribe('restartIntervalStarts', (res) => {
            this.navCtrl.pop();
            this.events.unsubscribe('restartIntervalStarts')
        });

        this.events.subscribe(this.addressPrefix + '_modelUpdated', (res) => {
            var me = this;
            setTimeout(function () {
                var adrs = me.order.data.address;
                if (!adrs && me.addresses.length) {
                    // for (var k in me.addresses) {
                        // if (me.addresses[k].data.onRadius && !adrs) {
                        adrs = me.addresses[0].data;
                        // return;
                        // }
                    // }
                }
                if (adrs) me.chooseAddress(new Address(adrs ? adrs : {}));
                me.setFirstStep()
            }, 300)
        })
        // const mexicoCity = new google.maps.LatLng(19.432608, -99.133209.);
        // this.setFirstStep()

    }

    ngAfterViewInit() {
    }

    setFirstStep() {
        if (this.choosedAddressId == -1)
            this.slideTo(1);
        // this.slides.lockSwipes(true);
    }

    newAddress() {
        this.slideTo(1);
    }

    chooseAddress(address) {
        // if (!address.data.onRadius) {
        //     this.notOnRadius = true;
        //     return;
        // }
        this.notOnRadius = false;
        this.orderService.setAddress(address)
        this.choosedAddress = address;
        this.choosedAddressId = address.data.id ? address.data.id : -1;
    }

    add() {
        // if (this.choosedAddressId == -1) {
        //     this.createAddress();
        //     return;
        // }
        //
        // this.order.data.shipmentAddress = this.choosedAddress.data.id;
        // this.order.data.address = this.choosedAddress.data;
        this.popoverCtrl.dismiss();
        this.events.publish('addressSets');
    }

    createAddress() {
        this.events.publish(this.formOptions['prefix']);
        if (!this.formOptions['valid']) return;

        this.address.data.appuser = this.appuser.data.id;
        this.modelProvider.create(this.address, {'disableLoading': true}).then((res: Address) => {
            this.formOptions['isSent'] = false;
            this.chooseAddress(res);
            this.addresses.push(res);
            if (this.notOnRadius)
                this.slideTo(0)
            else
                this.add();

            this.resetAddress()
        });
    }

    resetAddress() {
        this.address = null;
        var me = this;
        setTimeout(function () {
            me.address = new Address({});
        })
    }

    slideTo(key) {
        // this.slides.lockSwipes(false);
        this.slides.slideTo(key)
        // this.slides.lockSwipes(true);
    }
}
