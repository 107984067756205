import {Injectable} from '@angular/core';
import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {TranslateService} from "@ngx-translate/core";
import {Events} from "@ionic/angular";
import {AppConfig} from "../conf/main";
import {Storage} from "@ionic/storage";
import {OrderService} from "./OrderService";
import {BusinessService} from "./BusinessService";

@Injectable()
export class MainService {

    public dir;

    constructor(private modelProvider: ModelProvider,
                public translate: TranslateService,
                public orderService:OrderService,
                public businessService:BusinessService,
                public storage:Storage,
                public events: Events) {
    }

    ngOnInit() {
    }

    start(){
        this.setDefLangs();

        this.orderService.start();

        this.businessService.start();
    }

    public setDefLangs() {
        this.translate.addLangs(AppConfig.langs);
        this.translate.setDefaultLang(AppConfig.defaultLang);
        this.translate.use(AppConfig.defaultLang);
        this.setDir(AppConfig.defaultLang)
    }

    public setLang(lang) {
        this.translate.use(lang);
        this.setDir(lang)
    }

    public setDir(lang) {
        var arr = AppConfig.ltrLangs;

        for (var k in arr) {
            if (arr[k] == lang) {
                this.dir = 'ltr';
                return;
            }
        }
        this.dir = 'rtl';
        AppConfig.dir = this.dir
    }

    public getDir() {
        return this.dir;
    }

}









