import {Component, OnInit} from '@angular/core';

export class Order {
    data = {
        'id': null,
        'title': null,
        'orderTicketData': null,
        'objectId': null,
        'type': null,

        'name': null,
        'email': null,
        'coupon': null,
        'paymentStation': null,

        'cellphone': null,
        'contactMan': null,
        'address': null,
        'appuser': null,
        'paymentTypeName': null,
        'contentTypeName': null,
        'forTime': null,
        'isForTime': null,

        'finalPrice':null,
        'vatPrice':null,
        'orderPrice':null,
        'couponDiscount':null,

        'shipmentAdress':null,
        'orderAdress':null,
        'pickup':null,
        'shipmentFloor':null,
        'shipmentApt':null,
        'appuserName':null,
        'userEmail':null,
        'statusName':null,
        'paymentVerified':null,
        'comments':null,
        'newStatus':null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    frameworkModel = true;
    //shortHeaders = [];
    prefix = "order";
    title = "Order";
    defaultHeaders =
        ['name', 'countField', 'desktopStatFilter', 'contentType', 'puserType'];
    dataFields = {
        id: {'title': 'orderNo', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'date', 'value': null, 'type': 'datetime', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'datetime', 'readonly': true},
        receiveDate: {'title': 'receiveDate', 'value': null, 'type': 'datetime', 'readonly': true},
        employee: {
            'value': null,
            'displayField': 'employeeName',
            'type': 'modelscroll',
            'modelscroll': 'Employee',
            'keyField': 'id',
            'valueField': 'fname', 'required': true
        },
        status: {
            'showDefault': true, 'value': null, "displayField": 'statusName',
            'filters': {'contentTypeName': 'order', 'fieldName': 'status'},
            'type': 'modelscroll', 'modelscroll': 'ModelStatus', 'required': true,
            'keyField': 'id', 'valueField': 'name',
        },

        paymentType: {'title': 'paymentType', 'value': null, 'type': 'optionsscroll', 'displayfield':'paymentTypeName', 'primary': true},
        paymentStatus: {'title': 'paymentStatus', 'value': null, 'type': 'boolean', 'primary': true},
        paymentVerified: {'title': 'paymentStatus', 'value': null, 'type': 'boolean', 'primary': true},

        payedPrice: {'title': 'PayedPrice', 'value': null, 'type': 'float', 'primary': true},
        invoiceNumber: {'title': 'InvoiceNumber', 'value': null, 'type': 'text'},
        barcodeData: {'title': 'BarcodeData', 'value': null, 'type': 'text'},
        externalId: {'title': 'ExternalId', 'value': null, 'type': 'text', 'primary': true},
        verifyCode: {'title': 'VerifyCode', 'value': null, 'type': 'text', 'primary': true},
        orderTicket: {'type': 'oneToMany', 'class': 'orderTicket', 'foreignKey': 'order'},

        name: {'title': 'name', 'value': null, 'type': 'text', 'required':true},
        contactMan: {'title': 'name', 'value': null, 'type': 'text', 'maxlength':15},
        cellphone: {'title': 'cellphone', 'value': null, 'type': 'number', 'maxlength':10, 'required':true},
        orderAdress: {'title': 'address', 'value': null, 'type': 'number', 'readonly':true},
        email: {'title': 'email', 'value': null, 'type': 'email', 'required':true},
        comments: {'title': 'comments', 'value': null, 'type': 'textarea'},
        pickup: {'title': 'pickup', 'value': null, 'type': 'boolean'},
        isForTime: {'title': 'isForTime', 'value': null, 'type': 'boolean'},
        forTime: {'title': 'forTime', 'value': null, 'type': 'time'},
    }
}