import {Injectable} from '@angular/core';
import {ServerService} from "../server/server";
import {AuthModel} from "../auth-service/authModel";
import {User, UserData} from "../user-data";
import {ModelClasses} from "../../../core/model/modelClasses";
import {ToastController} from '@ionic/angular';

import {Events} from '@ionic/angular';
import {Image} from "../../model/general/image";
import {AppConfig} from "../../../core/conf/main";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ModelInterface {
    prefix: string;
    data: any = {};
    models: any = [];
    dataFields: any = {};
    title: string;
    icon: string;
    showDefaultsCols: boolean;
    defaultHeaders: any;

    constructor(public data1: any) {
        this.data = data1;
    }
}

@Injectable()
export class ResInterface {
    count: number;
    results: any;
    models: any;

    constructor() {
    }
}

@Injectable()
export class ModelProvider {
    constructor(public events: Events,
                public userData: UserData, public translate:TranslateService,
                private server: ServerService, public toastCtrl: ToastController,
                private modelClasses: ModelClasses, public authModel: AuthModel) {
        // this.storage = new Storage();
    }

    public create(model, filters = {}, tablePrefix = null) {
        return new Promise((resolve, reject) => {

            if (model.prefix == 'columnModel') return; // tmp fix. columnModel bug.
            this.checkFields(model);

            if (!filters['disableLoading'])
                this.loading()

            // this.presentToast(model.prefix + ' - creting.');
            return this.postModel(model.prefix, model, filters).then((res) => {
                this.loadingOff();
                // this.presentToast(model.prefix + ' has been added successfully.');
                this.presentToast(this.translate.instant('saved'), false);
                if (tablePrefix !== -1) {
                    if (tablePrefix) {
                        this.events.publish(tablePrefix + '_updated', {'data': res});
                        this.events.publish(tablePrefix + '_updateFilter', {'useCache': false});
                    } else {
                        this.events.publish(model.prefix + '_updated', {'data': res});
                        this.events.publish(model.prefix + '_updateFilter', {'useCache': false});
                    }
                }

                resolve(res);
            });
        })
    }

    public update(model, filters = {}, tablePrefix = null) {
        return new Promise((resolve, reject) => {
            this.checkFields(model);

            this.loading();

            // this.presentToast(model.prefix + ' - updating.');
            return this.put(model.prefix + "/" + model.data.id, model, filters).then((res) => {
                this.loadingOff();
                // this.presentToast(model.prefix + ' has been updated successfully');
                this.presentToast(this.translate.instant('saved'), false);
                if (tablePrefix !== -1) {
                    if (tablePrefix) {
                        this.events.publish(tablePrefix + '_updated', {'data': res});
                        this.events.publish(tablePrefix + '_updateFilter', {'useCache': false});
                    } else {
                        this.events.publish(tablePrefix + '_updated', {'data': res});
                        this.events.publish(model.prefix + '_updateFilter', {'useCache': false});
                    }
                }
                resolve(res);
            });
        });
    }

    public getUserToken() {
        return new Promise((resolve, reject) => {
            this.userData.getUserdata().subscribe((userData: User) => {
                resolve('Token ' + userData.data.token)
            })
        });
    }

    public remove(model, filters = {}, tablePrefix = null) {
        return this.removeById(model.data.id, model.prefix, filters)
    }

    public removeById(id, modelPrefix, filters = {}, tablePrefix = null) {
        if (!filters) {
            filters = {};
        }

        filters['ids'] = id;

        return new Promise((resolve, reject) => {
            // this.presentToast(modelPrefix + ' - removing.');
            this.userData.getUserdata().subscribe((userData: User) => {

                this.server.delete(userData.data.token, modelPrefix, filters)
                    .then((res) => {

                        // this.presentToast(modelPrefix + ' has been removed successfully, ID:' + id);
                        this.events.publish(modelPrefix + '_removed', {});
                        this.events.publish(modelPrefix + '_updateFilter', {'useCache': false});
                        if (tablePrefix)
                            this.events.publish(tablePrefix + '_updateFilter', {'useCache': false});

                        resolve({});
                    });
            });
        });
    }

    public list(modelPrefix, filters = {}, cache = true) {

        let myfilters = {};

        if (AppConfig.bisPrefix) myfilters['pref'] = AppConfig.bisPrefix;

        //copy filter to make sure object is not going to be change.
        for (var key in filters) {
            var value = filters[key]
            if (value === true) value = 'True';
            if (value === false) value = 'False';
            myfilters[key] = value;
        }

        if (!filters['disableLoading'])
            this.loading();
        var me = this;

        //console.log('in model provider get: user data:');
        var me = this;
        return new Promise((resolve, reject) => {

            this.userData.getUserdata().subscribe((userData: User) => {

                var storagePrfix = modelPrefix + JSON.stringify(myfilters);
                // load cache
                // if(cache)
                // {
                //     me.storage.get(storagePrfix).then((storageModelData) => {
                //         if (storageModelData) {
                //             var res = JSON.parse(storageModelData);
                //             var models = this.loadResults(modelPrefix, res.results);
                //
                //             resolve({'models': models, 'count': res.count});
                //         }});
                // }

                var token;
                if (userData)
                    token = userData.data.token;


                this.server.get(token, modelPrefix, myfilters)
                    .then((res: ResInterface) => {
                        //save cache
                        // this.storage.set(storagePrfix, JSON.stringify(res));

                        var models = this.loadResults(modelPrefix, res.results);
                        resolve({'models': models, 'count': res.count});
                        this.loadingOff(res['offline'])
                    });

            });
            //});
        });
    }

    public removeMultiple(prefix, ids, doObjcetFor = true) {
        //areyousureAlert('Are you sure?', "Delete " + model.prefix, function () {
        //serverFactory.remove(model.prefix + "/" + model.data.id,
        var selected;

        if (doObjcetFor) {
            selected = [];
            for (var i in ids) {
                if (ids[i])
                    selected.push(i)
            }
        } else
            selected = ids;

        if (selected.length == 0)
            return;

        // this.presentToast(prefix + ' - remove multiple records.');
        return new Promise((resolve, reject) => {
            this.userData.getUserdata().subscribe((userData: User) => {

                this.server.delete(userData.data.token, prefix, {'ids': selected.join(",")})
                    .then((res) => {

                        // this.presentToast(prefix + ' has been removed successfully, IDs:' + selected.join(","));
                        this.events.publish(prefix + '_updateFilter', {});

                        resolve(res);
                    });
            });
        });
        //}, "Done.");
    }

    public updateMultiple(model, models, filters = {}, tablePrefix = null) {
        var data = [];
        for (var p in models) {
            var d = models[p].data;

            //if (d.value && typeof (d.value) == 'object')
            //    d.value = d.value.join(',');

            data.push(d);
        }

        if (data.length == 0)
            return new Promise((resolve, reject) => {
                resolve({});
            });


        return new Promise((resolve, reject) => {

            this.putMultiple(model.prefix, data, filters).then((res) => {
                // this.presentToast(model.prefix + ' - updating multiple records.');

                if (tablePrefix)
                    this.events.publish(tablePrefix + '_updateFilter', {});
                else
                    this.events.publish(model.prefix + '_updateFilter', {});

                var models = this.loadResults(model.prefix, res);
                resolve({'models': models});

                // resolve(res);
            });
        });
    }

    public createMultiple(model, models, filters = {}, tablePrefix = null) {
        var data = [];
        for (var p in models) {
            var d = models[p].data;

            //if (d.value && typeof (d.value) == 'object')
            //    d.value = d.value.join(',');

            data.push(d);
        }

        return new Promise((resolve, reject) => {

            // this.presentToast(model.prefix + ' - adding multiple records.');
            this.post(model.prefix, data, filters).then((res) => {

                // this.presentToast(model.prefix + 's has been added successfully');

                if (tablePrefix)
                    this.events.publish(tablePrefix + '_updateFilter', {});
                else
                    this.events.publish(model.prefix + '_updateFilter', {});

                var models = this.loadResults(model.prefix, res);
                resolve({'models': models});

                // resolve(res);
            });
        });

        //serverFactory.add(model.prefix, data,
        //    function (data) {
        //        if (callback) callback(data);
        //        factory.mynotify('Created', model);
        //
        //        for (var key in model.dataFields) {
        //            if (model.dataFields[key].type == 'modelscroll') {
        //                $rootScope.$broadcast('model_scroll_update_event_' + key);
        //            }
        //        }
        //    }, false, filters);
    }

    public customExternal(prefix, filters = {}) {
        return new Promise((resolve, reject) => {
            this.server.get("", prefix, filters)
                .then((res: ResInterface) => {
                    resolve(res);
                });
        });
    }

    public searchDevice() {
        return new Promise((resolve, reject) => {
            for (var i = 1; i < 100; i++)
                this.server.searchDevice(i).then((res: Object) => {
                    // console.log(res.i);
                    resolve(res)
                });
        });
    }

    public custom(prefix, filters = {}, domain = '') {
        return new Promise((resolve, reject) => {
            this.userData.getUserdata().subscribe((userData: User) => {
                var token;
                if (userData)
                    token = userData.data.token;

                if (!domain) {
                    this.server.get(token, prefix, filters)
                        .then((res: ResInterface) => {

                            resolve(res);
                        });
                } else {

                    this.server.get(token, prefix, filters, domain)
                        .then((res: ResInterface) => {
                            resolve(res);
                        });
                }
            });
        });
    }

    public get(modelPrefix, id, filters = {}) {

        return new Promise((resolve, reject) => {

            this.userData.getUserdata().subscribe((userData: User) => {

                var token;
                if (userData)
                    token = userData.data.token;

                this.server.get(token, modelPrefix + "/" + id, filters)
                    .then((res: ResInterface) => {
                        // console.log(res);
                        //save cache
                        //this.storage.set(storagePrfix, JSON.stringify(res));

                        var models = this.loadResults(modelPrefix, [res]);
                        resolve({'models': models, 'count': res.count});
                    });
            });
        });


        //this.server.get(null, model.prefix + "/" + id, filters).then((res) => {
        //    //log("list taskTypeRow type result:");
        //    //log(data);
        //
        //    //var res = factory.loadResults(modelClass, data);
        //    var listmodel = factory.loadResults(modelClass, data);
        //
        //    if (callback) callback(listmodel, 1);
        //});
    }


    private postModel(url, model: ModelInterface, filters) {
        this.loading();

        //console.log("model:")
        //console.log(model)

        //array to object!!
        var data: Object = {};
        for (var key in model.data) {
            if (model.data[key] && Array.isArray(model.data[key]))
                data[key] = model.data[key].join(',');
            else
                data[key] = model.data[key];
        }

        return new Promise((resolve, reject) => {
            this.post(url, data, filters).then((res: Object) => {
                if (!res)
                    return;
                //update model with results

                //console.log('manyToMany???');

                for (var key in model.dataFields) {
                    if (model.dataFields[key]) {
                        model.data[key] = res[key];
                    }

                    if (model.dataFields[key].manyToMany) {
                        //console.log(model.data[key]);
                    }
                }

                resolve(model);
            });
        });

    }

    private post(url, data, filters) {
        if (AppConfig.bisPrefix) filters['pref'] = AppConfig.bisPrefix;
        filters['appctrl'] = AppConfig.appctrl;

        //filters
        var f = [];
        for (var key in filters) {
            f.push(key + '=' + filters[key]);
        }

        var urlwithparams = url + "/?" + f.join("&");

        return new Promise((resolve, reject) => {
            this.userData.getUserdata().subscribe((userData: User) => {

                var token;
                if (userData)
                    token = userData.data.token;

                this.server.post(token, urlwithparams, data)
                    .then((res) => {

                        resolve(res);
                    });
            });
        });
    }

    private putMultiple(url, models, filters) {

        //filters
        var f = [];
        for (var key in filters) {
            f.push(key + '=' + filters[key]);
        }

        //url
        var urlwithparams = url + "/?" + f.join("&");

        //array to object!!
        //var data = {};
        //for (var key in model.data) {
        //    if (model.data[key] && Array.isArray(model.data[key]))
        //        data[key] = model.data[key].join(',');
        //    else
        //        data[key] = model.data[key];
        //}

        return new Promise((resolve, reject) => {
            this.userData.getUserdata().subscribe((userData: User) => {

                this.server.put(userData.data.token, urlwithparams, models)
                    .then((res) => {

                        //update model with results
                        //for (var key in model.dataFields) {
                        //    if (res[key]) {
                        //        model.data[key] = res[key]
                        //    }
                        //}

                        // this.presentToast(models[0].prefix + ' has been update successfully');

                        resolve(res);
                    });
            });
        });
    }

    private put(url, model, filters) {
        this.loading();

        if (AppConfig.bisPrefix) filters['pref'] = AppConfig.bisPrefix;
        filters['appctrl'] = AppConfig.appctrl;

        // console.log(filters)

        //filters
        var f = [];
        for (var key in filters) {
            f.push(key + '=' + filters[key]);
        }

        //url
        var urlwithparams = url + "/?" + f.join("&");

        //array to object!!
        var data = {};
        for (var key in model.data) {
            if (model.data[key] && Array.isArray(model.data[key]))
                data[key] = model.data[key].join(',');
            else
                data[key] = model.data[key];
        }

        return new Promise((resolve, reject) => {
            this.userData.getUserdata().subscribe((userData: User) => {

                this.server.put(userData.data.token, urlwithparams, data)
                    .then((res) => {

                        //update model with results
                        //for (var key in model.dataFields) {
                        //    if (res[key]) {
                        //        model.data[key] = res[key]
                        //    }
                        //}
                        this.loadResultsSingle(model.prefix, model);
                        //console.log(model);

                        // this.presentToast(model.prefix + ' has been update successfully, ID:' + model.data.id);

                        resolve(res);
                    });
            });
        });
    }

    //public update(model, filters) {
    //    this.loading();
    //
    //    //array to object!!
    //    var data = {};
    //    for (var key in model.data) {
    //        if (model.data[key] && Array.isArray(model.data[key]))
    //            data[key] = model.data[key].join(',');
    //        else
    //            data[key] = model.data[key];
    //    }
    //
    //    return new Promise((resolve, reject) => {
    //        this.server.post(userData.data.token, model.prefix + "/" + model.data.id, data)
    //            .then((res) => {
    //                console.log(res);
    //                //if (!hideCallbackAlert)
    //                //    factory.mynotify('Updated', model);
    //
    //                //update model with results
    //                for (var key in model.dataFields) {
    //                    if (res.data[key]) {
    //                        model.data[key] = data.data[key]
    //                    }
    //                }
    //
    //            });
    //    });
    //}

    public uploadfile(model, file, filters) {
        return new Promise((resolve, reject) => {
            this.userData.getUserdata().subscribe((userData: User) => {

                this.server.upload(userData.data.token, model.prefix, file, filters, model.data).then((res) => {

                    //var listmodel = factory.loadResults(modelClass, data);
                    //if (callback) callback(listmodel, data.count)

                });

            })
        })
    }

    public uploadGeneralFile(prefix, file, filters, callback) {
        //serverFactory.upload(prefix, file, filters, {},
        //    function (data) {
        //        if (callback) callback(data, data.count)
        //    }
        //);
    }

    public saveGeneralFile(prefix, data, filters, callback) {
        //serverFactory.add(prefix, data,
        //    function (result) {
        //        if (callback) callback(result)
        //    }, true, filters);
    }


    public loadResults(modelPrefix, results) {
        var modelClass = this.modelClasses.getClass(modelPrefix);
        var models = [];
        for (var key in results) {

            var listmodel = new modelClass(results[key]);

            this.loadResult(listmodel);

            models.push(listmodel);
        }
        return models;
        //
    }

    public loadResultsSingle(modelPrefix, model) {

        var modelClass = this.modelClasses.getClass(modelPrefix);
        var listmodel = new modelClass(model);

        return this.loadResult(listmodel);

    }

    private loadResult(listmodel) {
        //custom data load array.
        //eg: extraData:{"dateTrip": {'model':'Trip'},},
        //?????
        //for (var key in listmodel.extraData) {
        //    if (listmodel.data[key]) {
        //        var d = [];
        //        for (var modelDataKey in listmodel.data[key]) {
        //            d.push(this.loadResults(listmodel.extraData[key].model, listmodel.data[key][modelDataKey]));
        //        }
        //        listmodel.data[key] = d;
        //    }
        //}
        //

        if (listmodel.data['images']) {
            var images = [];
            for (var key in listmodel.data['images']) {
                images.push(new Image(listmodel.data['images'][key]));
            }
            listmodel.data['images'] = images;
        }

        for (var key in listmodel.dataFields) {
            //one to one
            if (listmodel.dataFields[key] && listmodel.dataFields[key].type == 'modelscroll') {
                if (listmodel.data[key + 'Data']) {

                    //listmodel.data[key + 'Data'] = this.loadResults(
                    //    listmodel.dataFields[key].modelscroll,
                    //    listmodel.data[key + 'Data']);

                    var modelClass = this.modelClasses.getClass(listmodel.dataFields[key].modelscroll);
                    var modeldata = new modelClass(listmodel.data[key + 'Data']);
                    listmodel.data[key + 'Data'] = modeldata;
                    this.loadResult(modeldata);

                    //listmodel.data[key + 'Data'] = this.loadResults(
                    //    listmodel.dataFields[key].modelscroll,
                    //    listmodel.data[key + 'Data']);
                }
            }

            //one to many
            //array of other models;
            if (listmodel.dataFields[key] && listmodel.dataFields[key].type == 'oneToMany') {
                var models = listmodel.data[key];
                //var forigenKeyCls = MyClassFactory.getClasses()[listmodel.dataFields[key].class];

                var newmodels = [];
                for (var i in models) {
                    //newmodels.push(new forigenKeyCls(models[i]))
                    //console.log('load results of: ' + key);
                    //console.log(models[i]);

                    newmodels.push(this.loadResultsSingle(listmodel.dataFields[key].class, models[i]));
                }

                listmodel.data[key + 'Data'] = newmodels;
            }

            //One to One
            if (listmodel.dataFields[key] && listmodel.dataFields[key].oneToOne) {

                var key: string = listmodel.dataFields[key].modelDataKey;

                var modelClass = this.modelClasses.getClass(listmodel.dataFields[key].oneToOne);
                var modeldata = new modelClass(listmodel.data[key]);
                listmodel.data[key + 'Data'] = modeldata;
            }
        }

        return listmodel;
    }

    //open new window with prefix link.
    public newWindow(prefix, filters) {
        //urlfilters = '';
        //for (var f in filters) {
        //    urlfilters += f + '=' + filters[f] + '&'
        //}
        //window.open('http://' + SETTINGS.dataServerIp + "/" + prefix + "/?" + urlfilters);
    }

    //custom page - no model.
    // dont run over resData
    // public custom(prefix, callback, errcallback, filters, resData) {
    //serverFactory.list(prefix, filters,
    //    function (data) {
    //        if (callback) callback(data);
    //
    //        if (resData)
    //            for (var f in data) {
    //                resData[f] = data[f];
    //            }
    //
    //    }, errcallback);
    // }

    //custom page
    //run over res data
    public simpleUrl(prefix, callback, errcallback, filters, resData) {
        //serverFactory.list(prefix, filters,
        //    function (data) {
        //        if (callback) callback(data);
        //
        //        resData['result'] = data;
        //
        //    }, errcallback);
    }

    //loading bar on top of ther screen.
    //show loading animation
    public loading() {
        this.events.publish('setLoadingOn');

        //$.ytLoad({
        //    registerAjaxHandlers: false
        //});
        //$.ytLoad('start');
    }

    public loadingOff(offline = false) {
        if (offline)
            this.events.publish('setLoadingOffline');
        else
            this.events.publish('setLoadingOff');

        //$.ytLoad({
        //    registerAjaxHandlers: false
        //});
        //$.ytLoad('start');
    }

    // show alert
    public mynotify(title, model) {
        //log(model.prefix)
        //notify($filter('fix')("saved"),
        //    model.prefix + " " + title + " " + (model.getTitle ? model.getTitle(model) : '#' + model.data.id),
        //    "success", "font-icon-check-circle");


    }

    //stop loading animation
    public stopLoading() {
        //$.ytLoad({
        //    registerAjaxHandlers: false
        //});
        //$.ytLoad('complete');
    }

    //TODO: Don't need to be here.
    //run over model fields and return only oneToMany fields
    public getOneToManyFields(fields, modelName) {
        //var cls = MyClassFactory.getClass(modelName);
        //var model = new cls({});
        //
        //for (var f in model.dataFields) {
        //    if (model.dataFields) {
        //        if (model.dataFields[f].type == 'oneToMany') {
        //            fields[f] = f;
        //        }
        //    }
        //}
        return fields;
    }


    //retake model - keep reference.
    public refreshModel(model, scope, readyKey, filters) {
        //scope[readyKey] = false;
        //this.get(model, model.data.id, model.prefix, function (data) {
        //    for (var key in model.dataFields) {
        //        //if (data.data[key])
        //        //{
        //        model.data[key] = data.data[key];
        //        //}
        //
        //        if (model.dataFields[key].displayField) {
        //            model.data[model.dataFields[key].displayField] = data.data[model.dataFields[key].displayField];
        //        }
        //
        //    }
        //    scope[readyKey] = true;
        //}, filters)
    }

    public getInfoTabs(model) {
        if (model.infoTabs)
            return model;

        var index = 1;
        var i = 1;
        model.infoTabs = ['Info 1'];
        var len = Object.keys(model.dataFields).length;
        var lines = Math.ceil(len / 3) < 5 ? 5 : Math.ceil(len / 3);

        for (var field in model.dataFields) {
            if (model.dataFields[field].type == 'oneToMany')
                continue

            if (i == (lines)) {
                i = 1;
                index++;

                model.infoTabs.push('Info ' + index);
            }

            model.dataFields[field]['tab'] = 'Info ' + index;
            i++;
        }

        return model;
    }

    //
    // presentToast(str) {
    //     let toast = this.toastCtrl.create({
    //         message: str,
    //         duration: 3000
    //     });
    //     toast.present();
    // }

    async presentToast(str, allowed = true, color = 'dark') {
        if(AppConfig.type != 'admin' && !allowed)
            return
        const toast = await this.toastCtrl.create({
            message: str,
            duration: 3000,
            color: color
        });
        toast.present();
    }

    checkFields(model) {
        for (var k in model.data) {
            if (model.dataFields[k])
                if (model.dataFields[k].type == 'number' || model.dataFields[k].type == 'float')
                    if (!model.data[k] || model.data[k] == '') model.data[k] = 0;
        }
    }
}
