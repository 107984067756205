import {Component, OnInit} from '@angular/core';
import {ResObject} from "../../../../../app/global-module/global-module.module";
import {ModelProvider} from "../../../../../framework/providers/modelProvider/ModelProvider";
import {TranslateService} from "@ngx-translate/core";
import {OrderService} from "../../../../services/OrderService";
import {PopoverController} from "@ionic/angular";

@Component({
    selector: 'coupons',
    templateUrl: './coupons.component.html',
    styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {

    public couponText: string;
    public cartData: object;

    public loading: boolean;

    constructor(public modelProvider: ModelProvider, public translate: TranslateService, public orderService: OrderService,
                public popoverctrl:PopoverController) {
    }

    ngOnInit() {
        this.cartData = this.orderService.getCartData()
    }


    findCoupon(text) {
        if (!text || text == '') return;
        let coupon;
        this.loading = true;
        this.modelProvider.list('coupon', {'code': text, 'disableLoading': true}).then((res: ResObject) => {
            this.loading = false;

            if (!res['models'].length) {
                coupon = null;
                this.modelProvider.presentToast(this.translate.instant('notValidCoupon'))
            } else {
                coupon = res['models'][0]
            }

            this.orderService.setCoupon(coupon);
        })
    }

    removeCoupon() {
        this.orderService.setCoupon(null)
    }


    close() {
        this.popoverctrl.dismiss();
    }

}
