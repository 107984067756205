import {Component, Input, OnInit} from '@angular/core';
import {Events, IonSlides, Platform} from "@ionic/angular";

interface OptionsData {
    isStart?: boolean;
    isEnd?: boolean;
}

@Component({
  selector: 'dynamic-slider',
  templateUrl: './dynamic-slider.component.html',
  styleUrls: ['./dynamic-slider.component.scss'],
})

export class DynamicSliderComponent implements OnInit {


    @Input()
    public data: any;

    @Input()
    public slidesPerView: number = 3;

    @Input()
    public fields: any;

    @Input()
    public callback: any;

    @Input()
    public goToOnClick: boolean;

    @Input()
    public showArrows: boolean = true;

    @Input()
    public onClickPrefix: string;

    @Input()
    public activeModel: string;

    @Input()
    public checkedActive: object = {};

    @Input()
    public checkedDataActive: object = {};

    @Input()
    public eventData: object = {};

    @Input()
    public imageHeight: number = 120;

    @Input()
    public amountBox: number = 0;

    @Input()
    public slidesByWindowSize: boolean = false;

    @Input()
    public goToPage: any;

    @Input()
    public options: OptionsData = {'isStart':true};

    public slidesLength;

    public slides: IonSlides;
    public sliderVisible = 'hidden'
    public slidesPrefix = 'slides_' + Math.floor(Math.random() * Math.floor(1000000));
    public pages: object;

    public slidesOpts: object;
    public sliderReachStart:boolean;
    public sliderReachEnd:boolean;

    constructor(public events: Events, public platform: Platform) {
    }

    ngOnInit() {
        this.events.subscribe(this.slidesPrefix, (params) => {
            var me = this;
            setTimeout(function () {
                me.slides = params['slides']
                // console.log(me.slides.ionSlideReachStart())
                // console.log(me.slides.ionSlideReachEnd())

                me.setSliderEvents()
            })


        })


        // if(this.slidesByWindowSize)
        // {
        this.platform.ready().then((readySource) => {
            var defSize = 360;
            var slidePerSize = defSize / this.slidesPerView;
            var width = this.platform.width()
            if(width > 768) width = 768;
            this.slidesLength = Math.round(width / slidePerSize)
        });

        // }
    }

    ngAfterViewInit() {
        var me = this;
        setTimeout(function () {
            me.sliderVisible = 'shown'
        }, 300)
    }

    scrollNext() {
        this.slides.slideNext()
    }

    scrollPrev() {
        this.slides.slidePrev()
    }

    setSliderEvents()
    {
    }
}
