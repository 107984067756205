import {Component, OnInit} from '@angular/core';

export class Deal {
    data = {
        id: null,
        name: null,
        price: null,
        groups: null,
        images: null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "deal";
    title = "Deal";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        business: {'title': 'business', 'value': null, 'type': 'text', 'readonly': true},
        name: {'title': 'name', 'value': null, 'type': 'text', 'required': true},
        price: {'title': 'price', 'value': null, 'type': 'float', 'nis': true},
        comments: {'title': 'comments', 'value': null, 'type': 'textarea'},
    }
}
