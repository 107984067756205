export class MainAdvertise {
    data = {
        'title': null,
        'id': null,
        'images':null
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "mainAdvertise";
    title = "Advertise";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        title: {'title': 'title', 'value': null, 'type': 'text'},
        mainBanner: {'title': 'mainBanner', 'value': null, 'type': 'boolean'},
        itemId: {'showDefault':true, 'title':'item', 'value':null,
                'filters':{'contentTypeName':'itemId', 'fieldName':'itemId'},
                'displayField':'itemName', 'type':'modelscroll', 'selfTemplate':true,
                'modelscroll':'Item', 'keyField':'id', 'valueField':'name', 'noneOption': true},
        deal: {'showDefault':true, 'title':'deal', 'value':null,
                'filters':{'contentTypeName':'deal', 'fieldName':'deal'},
                'displayField':'dealName', 'type':'modelscroll', 'selfTemplate':true,
                'modelscroll':'Deal', 'keyField':'id', 'valueField':'name', 'noneOption': true},
    }
}