import { Component, OnInit } from '@angular/core';

export class Filter {
    data = {
        'name':'',
        'id':''
    };

    constructor(public data1:any) {
        this.data = data1;
    }

    frameworkModel = true;
    prefix = "filter";
    title = "Filter";

    showDefaultsCols = true;
    defaultHeaders = ['name', 'contentType'];
    dataFields = {
        "created": {'value': null, 'type': 'date', 'readonly': true},
        "id": {'value': null, 'type': 'number', 'readonly': true},
        "name": {'value': null, 'type': 'text', 'required': true},
        //"exclude": {'value': null, 'type': 'boolean', 'primary': true},

        "fields": {'value': null, 'type': 'textarea', 'primary': true},

        "contentType": {
            'value': null, 'type': 'modelscroll',
            'displayField': 'contentTypeName', 'required': true,
            'modelscroll': 'ContentType', 'keyField': 'id', 'valueField': 'model'
        },
        "filterField": {'type': 'oneToMany', 'class': 'FilterField', 'foreignKey': 'filter'},
    }
}
