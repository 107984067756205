import {Injectable} from '@angular/core';

import {Storage} from "@ionic/storage";

import {ModelProvider} from "../../framework/providers/modelProvider/ModelProvider";
import {UserData} from "../../framework/providers/user-data";
import {AuthService} from "../../framework/providers/auth-service/auth-service";
import {AppConfig} from "../conf/main";
import {AlertController, Events, PopoverController} from "@ionic/angular";
import {Order} from "../../framework/model/core/order/order";
import {AppUser} from "../../framework/model/core/appuser/appuser";
import {Business} from "../../framework/model/core/business/business";
import {BusinessService} from "./BusinessService";
import {ActivatedRoute} from "@angular/router";
import {Item} from "../../framework/model/core/items/item";
import {Address} from "../../framework/model/core/address/address";

// import {OrderStatusAlertComponent} from "../admin/component/order-status-alert/order-status-alert.component";

@Injectable()
export class OrderService {

    cart: Object;
    cartData: Object;
    order: Order;
    orderId: number;
    totalCharge: number;

    appuser: AppUser;
    business: Business;
    address: Address;

    appuserPrefix = 'appuser'

    pageName: string;
    adminUserLogin: boolean = false;
    orderStatusAlertVisible: boolean = false;

    constructor(private modelProvider: ModelProvider,
                private auth: AuthService,
                public userData: UserData,
                public businessService: BusinessService,
                public storage: Storage,
                public route: ActivatedRoute,
                public events: Events) {

        this.events.subscribe('businessSets', (res) => {
            this.businessSets(res);
        })
    }

    start() {
        this.initCart();
        this.initOrder();
    }

    public logout() {
        this.order = null;
        this.cart = {};
        this.totalCharge = null;
        this.appuser = null;
        this.auth.logout()
    }

    businessSets(params = {}) {
        this.business = this.businessService.getBusiness();
        this.initOrder();

        if(params['cart'])
            this.cartItemsByPrefix(params['cart']);

        // this.route.queryParams.subscribe(params => {
        //     let cartItemsPrefix = params['cart'];
        console.log(this.cartData)
        if(this.cartData['cartPrefix'])
            this.cartItemsByPrefix(this.cartData['cartPrefix']);
        // });

        // this.getCartFromStorage(true);
    }


    initCart() {
        this.cart = {};
        this.cartData = {'amount': 0, 'totalCharge': 0, 'coupon':null, 'couponDiscount':0};
        this.totalCharge = 0;
    }

    initOrder() {
        if(!this.order) this.order = new Order({});
        // let address = {'city': 'תל אביב', 'street': 'הארבעה', 'homeNumber': 1, 'floor': 3, 'apt': 4};
        let address;
        let data: any = {'address': address, 'paymentTypeName': 'creditCard'};

        if (this.order) {
            data = {
                'name': this.order.data.name,
                'email': this.order.data.email,
                'cellphone': this.order.data.cellphone,
                'contactMan': this.order.data.contactMan,
                'address': this.order.data.address,
                'type': this.order.data.type,
                'shipmentAdress': this.order.data.shipmentAdress,
                'appuser': this.order.data.appuser,
                'paymentTypeName': this.order.data.paymentTypeName,
            }
        }

        let order = new Order(data)
        this.order['data'] = order.data;

        return this.getOrder();
    }

    setCartPrefix(prefix)
    {
        this.cartData['cartPrefix'] = prefix;
    }

    cartItemsByPrefix(prefix)
    {
        this.modelProvider.list('cartItem', {'prefix':prefix, 'disableLoading': true}).then((res)=>{
            // this.orderService.addToBasket(ticket, this.item, 1, null, null, price);
            if(!res['models'].length) return;

            for(let k=0; k<res['models'].length; k++) {
                let cartItem = res['models'][k]
                let item = new Item(cartItem.data['itemData'])
                let ticket = {'data': {'id': 1}};
                let price = cartItem.data.price;
                this.addToBasket(ticket, item, 1, null, null, price, price);
                this.cartData['massof'] = cartItem.data.massof
                this.cartData['station'] = cartItem.data.paymentStation
            }

            let cren = {
                'username': res['models'][0].data.username,
                'password': res['models'][0].data.username
            }
            this.auth.login(cren)
                .subscribe(allowed => {
                        this.events.publish('setLoadingOff');
                        if (allowed) {
                            // this.events.publish('user:login');
                            this.listAppuser(cren['username'])

                        } else {

                        }
                    });
        })
    }

    getOrder() {
        return this.order
    }

    getAddress() {
        return this.address
    }

    setOrderType(type) {
        // console.log(this.order)
        this.order.data.type = type;
    }

    setAppUser(appuser) {
        this.initOrder();

        this.appuser = appuser;
        this.order.data.appuser = this.appuser.data.id;
        this.order.data.name = this.appuser.data.name;
        this.order.data.email = this.appuser.data.email;
        this.order.data.contactMan = this.appuser.data.name;
        this.order.data.cellphone = this.appuser.data.cellphone;

        this.getDefaultAddress();

        // this.saveAppUserToStorage(this.appuser);
        // this.login(this.appuser.data.username, this.appuser.data.username);
    }

    getAppUser() {
        return this.appuser;
    }

    listAppuser(username)
    {
        this.modelProvider.list('appuser', {'username':username, 'disableLoading': true}).then((res)=>{
            this.setAppUser(res['models'][0])
        })
    }

    resetAppUser() {
        // this.saveAppUserToStorage(null);
        this.logout();
        // this.events.publish('appuserSets', {'appuser': null});
        // return this.appuser;
    }

    addToBasket(ticket, product, amount, orderProduct = null, items = null, price = null, fixedprice = null) {
        // console.log('add to basket')
        // console.log(ticket.data.name)
        // console.log(product.data.name)
        // console.log(amount)
        // console.log(orderProduct ? orderProduct.data.id : 0)

        this.initTicket(ticket);

        let index = 100000 - this.cartData['amount']; // makes new items to the top of the obj.

        // copy models;
        items = this.copy(items)
        product = this.copy(product)
        price = price !== null ? price : product.data.price;

        if (!this.cart[ticket.data.id]['products'][index]) {
            this.cart[ticket.data.id]['products'][index] = {
                'product': product,
                'amount': 0,
                'type': product.prefix,
                'items': items,
                'price': price,
                'fixedPrice': fixedprice
            };
            this.cartData['amount'] += 1;
        }

        this.cart[ticket.data.id]['products'][index]['amount'] += amount;

        if (orderProduct)
            this.cart[ticket.data.id]['products'][index]['orderProduct'] = orderProduct;

        // if (this.cart[ticket.data.id]['products'][index]['amount'] < 0) {
        //     this.cart[ticket.data.id]['products'][index]['amount'] = 0;
        //     this.cartData['amount']-=1;
        // }
        this.updateTotalCharge();
        // this.saveCartOnStorage();
        this.cartUpdated(true);
    }

    initTicket(ticket) {
        if (!this.cart[ticket.data.id])
            this.cart[ticket.data.id] = {'products': {}, 'price': 0, 'ticket': ticket};
    }

    getAmount(ticket, product) {
        if (!this.cart[ticket.data.id]) return 0
        if (!this.cart[ticket.data.id]['products'][product.data.id]) return 0
        return this.cart[ticket.data.id]['products'][product.data.id]['amount']
    }

    removeFromCart(ticketId, productKey, alert = null) {
        if (!this.cart[ticketId]) return
        if (!this.cart[ticketId]['products'][productKey]) return

        this.removeItem(ticketId, productKey);

    }

    removeItem(ticketId, productKey) {
        this.cartData['amount'] -= 1;
        this.cartData['totalCharge'] -= this.cart[ticketId]['products'][productKey]['price']
        this.cartData['charge'] -= this.cart[ticketId]['products'][productKey]['price']
        delete this.cart[ticketId]['products'][productKey]
        this.cartUpdated(true)
        this.saveCartOnStorage();
    }

    clearCart() {
        this.initCart();
        this.cartUpdated(true);
        this.saveCartOnStorage();
    }

    cartUpdated(newItem = false) {
        this.events.publish('cartUpdated', {'new': newItem})
    }

    getCartAmount() {
        return this.cartData['amount']
    }

    getCartData() {
        return this.cartData
    }

    getCart() {
        return JSON.parse(JSON.stringify(this.cart));
    }

    updateTotalCharge() {

        this.totalCharge = 0;

        for (let ticketKey in this.cart) {
            let ticketPrice = 0;

            for (let productKey in this.cart[ticketKey]['products']) {
                let price = this.cart[ticketKey]['products'][productKey].price
                    * this.cart[ticketKey]['products'][productKey]['amount'];

                this.totalCharge += price;
                ticketPrice += price;
            }
            this.cart[ticketKey]['price'] = ticketPrice;
        }

        this.cartData['charge'] = this.totalCharge;
        if (this.business.data.GlobalSettingsData.shippingPrice && this.order.data.type == 'delivery')
            this.totalCharge += this.business.data.GlobalSettingsData.shippingPrice;

        if (this.business.data.GlobalSettingsData.vat) {
            this.cartData['vat'] = (this.totalCharge * this.business.data.GlobalSettingsData.vat) / 100;
            this.totalCharge += this.cartData['vat'];
        }

        this.totalCharge = this.totalCharge - this.cartData['couponDiscount'];
        if(this.totalCharge < 0 ) this.totalCharge = 0;

        this.cartData['totalCharge'] = this.totalCharge
    }

    saveCartOnStorage() {
        // if(AppConfig.stationMode)
        //     return;

        let data = {'cart': this.cart, 'cartData': this.cartData};
        this.storage.set('cart_' + AppConfig.bisPrefix + '_' + this.appuser.data.id, data);
    }

    getCartFromStorage(init = false) {
        if (!this.appuser) return;
        this.storage.get('cart_' + AppConfig.bisPrefix + '_' + this.appuser.data.id).then((res) => {
            if (!res) {
                this.initCart();
                this.cartUpdated(init);
                return;
            }
            this.cart = res.cart
            this.cartData = res.cartData
            this.cartUpdated(init);
        });
    }

    getDefaultAddress()
    {
        // this.modelProvider.list('shipmentAdress', {'appuser':this.appuser.data.id, 'limit':1, 'disableLoading': true}).then((res)=>
        //         // {
        //         //     if(!res['models'].length) return;
                    this.setAddress(new Address({}));
        //         // })

    }

    setAddress(address)
    {
        this.address = address;
        this.order.data.shipmentAdress = address.data.id;
        this.order.data.address = address.data;
        this.events.publish('orderAddressSets');
    }

    setCoupon(coupon)
    {
        let discount = 0;
        if(!coupon) {
            this.order.data.coupon = null;
            discount = 0;
        }
        else {
            this.order.data.coupon = coupon.data.id;
            if(coupon.data.isPercentage)
            {
              discount = this.cartData['charge'] / 100 * coupon.data.discount
            }
            else
            {
              discount = coupon.data.discount
            }
        }

        this.cartData['coupon'] = coupon;
        this.cartData['couponDiscount'] = discount;
        this.updateTotalCharge();

    }

    copy(data) {
        return JSON.parse(JSON.stringify(data))
    }
}









