import {Component, Input, OnInit} from '@angular/core';
import {Events, NavController, PopoverController} from "@ionic/angular";
import {ModelProvider} from "../../../providers/modelProvider/ModelProvider";
import {ModelClasses} from "../../../../core/model/modelClasses";
import {ModelToolsProvider} from "../../../providers/model-tools/model-tools";
import {AppModelScrollComponent} from "./app-model-scroll/app-model-scroll.component";

@Component({
  selector: 'modelscroll',
  templateUrl: './modelscroll.component.html',
  styleUrls: ['./modelscroll.component.scss'],
})
export class ModelscrollComponent implements OnInit {
    @Input()
    fieldkey:string;

    @Input()
    model:any;

    @Input()
    hideTitle:boolean;

    @Input()
    navCtrl:NavController;

    @Input()
    tableOnPopup:boolean = false;

    @Input()
    modelScrollFilter:Object;

    @Input()
    addParams:Object = {};

    @Input()
    fieldsFilters:Object = {};

    @Input()
    editable:boolean = true;

    @Input()
    showFirstOption:boolean = true;

    @Input()
    placeholder:boolean = false;

    @Input()
    disabled:boolean = false;

    @Input()
    options:object = {};

    public models:Array<any>;
    public fieldData:any;
    public filters:Object;
    public fieldModelClass:string;

    public displayValue:string;
    public displayValueList:Array<string> = [];

    public title:string;
    public keyField:string;
    public valueField:string;
    public selectModels:Object;
    public selectModel:Object;
    // public options:Array = [];
    public defaultFilters:Object;

    public ready:boolean;

    @Input()
    public tablePrefix;

    public listPrefix:string = 'modelScrollList_'+Math.floor((Math.random() * 1000) + 1);
    public changePrefix:string = 'modelScrollChanged_'+Math.floor((Math.random() * 1000) + 1);

    constructor(public events:Events,
                public popoverCtrl:PopoverController,
                public modelProvider:ModelProvider,
                public modelClasses:ModelClasses,
                public modelTools:ModelToolsProvider) {
        // super(clipboard,
        // 	events,
        // 	popoverCtrl,
        // 	navParams,
        // 	modelProvider);
    }

    ngOnInit()
    {
        this.loadData()

        this.events.subscribe(this.tablePrefix+'_updateModelScroll', (params) => {
            for (var k in params)
            {
                this.filters[k] = params[k];
            }
            this.events.publish(this.listPrefix+'_updateFilter', this.filters);
        });

        this.events.subscribe(this.changePrefix, (params) => {
            this.setChosenOptions(params);
            this.events.publish(this.tablePrefix+'_modelScrollChanged', params)
        });

        this.events.subscribe(this.listPrefix +'_modelUpdated', (params) => {
            this.models = params.models;
            this.setInitData();
        });
    }

    ngOnDestroy() {
        this.events.unsubscribe(this.changePrefix)
    }

    loadData() {
        this.fieldModelClass = this.model.dataFields[this.fieldkey].modelscroll;
        var cls = this.modelClasses.getClass(this.fieldModelClass);
        var selectModel = new cls({});

        this.fieldData = this.model.dataFields[this.fieldkey];

        this.title = this.fieldData.title ? this.fieldData.title : this.fieldkey;

        this.valueField = this.fieldData.valueField;
        this.keyField = this.fieldData.keyField;

        if(this.fieldsFilters)
          this.defaultFilters = this.fieldsFilters[this.fieldkey]
        if(!this.defaultFilters) this.defaultFilters = {}
        this.defaultFilters['limit']=5;

        var filters = this.fieldData.filters ? this.fieldData.filters : {};
        filters = this.fieldData.filterFunction ? this.model[this.fieldData.filterFunction](this.model, filters) : filters;
        this.filters = filters;

        //from directive add - autofield..
        if (this.modelScrollFilter) {
            for (var key in this.modelScrollFilter) {
                this.filters[key] = this.modelScrollFilter[key];
            }
        }

        if (this.model.onAddManyField && this.model.onAddManyField == this.fieldkey) {
            this.model.data[this.fieldkey] = {};
            this.model.data[this.fieldkey+'Data'] = {};
        }

        this.models = []
        this.ready = true;
        // this.modelProvider.list(selectModel.prefix, filters).then((data:ResInterface) => {
        //     this.models = data.models;
        //     this.setInitData();
        // });
    }


    async openScroll() {
         var data = {
            'title': this.title,
            'models':this.models,
            'model':this.model,
            'fieldkey':this.fieldkey,
            'valueField':this.valueField,
            'keyField':this.keyField,
            'changePrefix':this.changePrefix,
            'multipleSelect':this.fieldData['multipleSelect'],
            'tablePrefix':this.tablePrefix,
            'listPrefix':this.listPrefix,
            'addParams':this.addParams,
            'filters':this.filters,
            'options':this.options,
        }

        const popover = await this.popoverCtrl.create({
            component: AppModelScrollComponent,
            componentProps: data,
            event: null,
            animated: true,
            showBackdrop: true,
            cssClass:'center-popover'
        });
        return await popover.present();
    }

    setChosenOptions(params)
    {
        this.displayValueList = []
        this.model.data[this.fieldkey] = params['data']

        if(!this.model.data[this.fieldkey].length) return;
        var modelsKeys = params['data'].split(',')
        var models = params['models']
        for(var k in modelsKeys)
        {
            this.displayValueList.push(models[modelsKeys[k]].data[this.valueField])
        }
        if(!this.fieldData.multipleSelect)
        {
            this.model.data[this.fieldkey+'Data']=models[modelsKeys[0]];
            this.events.publish("fieldChanged_" + this.fieldkey,
                    {'model': this.model.data[this.fieldkey + 'Data']});
        }

    }

    setInitData() {
        if (!this.model.data[this.fieldkey])
        {
            // this.setFirstOption();
            return;
        }
        this.model.data[this.fieldkey] = this.model.data[this.fieldkey]+'';
        if(!this.model.data[this.fieldkey].length)
        {
            // this.setFirstOption();
            return;
        }

        var modelsKeys = this.model.data[this.fieldkey].split(',')
        this.displayValueList = []
        for(var k in this.models) {
            if (this.ifOnArr(modelsKeys, this.models[k].data[this.keyField])) {
                this.displayValueList.push(this.models[k].data[this.valueField]);
                if(!this.fieldData.multipleSelect)
                {
                    this.model.data[this.fieldkey+'Data']=this.models[k];
                    this.events.publish("fieldChanged_" + this.fieldkey,
                            {'model': this.model.data[this.fieldkey + 'Data']});
                }
            }
        }
    }

    setFirstOption()
    {
        if(!this.models.length) return;
        if(this.fieldData.disableSetsFirstOption) return;
        var model = this.models[0]
        var data = model.data[this.keyField]+''
        var models = {}
        models[model.data[this.keyField]] = model;
        this.setChosenOptions({'data':data, 'models':models})
    }

    ifOnArr(arr, value)
    {
        for(var k in arr)
        {
            if(arr[k]+'' == value+'') return true;
        }
        return false;
    }
}
