export const AppConfig = {
    // dataServerIp: '192.168.1.18:220',
    dataServerIp: 'onebutton.co.il/oneadmin0.7',
    httpServerIp: 'https://onebutton.co.il/oneadmin0.7',
    startSearchIp: 'https://onebutton.co.il/oneadmin0.7',
    // http: 'http://',
    http: 'https://',
    // assets:'assets/',
    assets:'https://onebutton.co.il/shaps/assets/',

    // dataServerIp: '10.0.0.25:9000',
    // httpServerIp: '10.0.0.25:9000',

    appctrl: false,

    ver: "2.0",

    bisPrefix: null,
    // bisPrefix: 'dr-gav',

    appName: 'Shaps',
    type:'app',

    defaultLang: 'heb',
    langs: ['heb', 'en'],
    ltrLangs: ['en'],
    dir:'rtl',
};

// dataServerIp = '10.100.102.11:221';
// httpServerIp = 'http://10.100.102.11:221/';
//dataServerIp = 'onebutton.co.il/movingaid';
//httpServerIp = 'http://onebutton.co.il/movingaid/';