export class CartItem {
    data = {
        'id': null,
        "prefix": null,
        "username": null,
        "item": null,
        "amount": null,
        "price": null,
        "appuser": null,
    };

    constructor(public data1: any) {
        this.data = data1;
    }

    //shortHeaders = [];
    prefix = "cartItem";
    title = "Cart Item";
    dataFields = {
        id: {'title': 'ID', 'value': null, 'type': 'number', 'readonly': true},
        created: {'title': 'Created', 'value': null, 'type': 'date', 'readonly': true},
        updated: {'title': 'Updated', 'value': null, 'type': 'date', 'readonly': true},
        prefix: {'title': 'prefix', 'value': null, 'type': 'text'},
        username: {'title': 'prefix', 'value': null, 'type': 'text'},
        item: {'title': 'prefix', 'value': null, 'type': 'text'},
        amount: {'title': 'prefix', 'value': null, 'type': 'text'},
        price: {'title': 'prefix', 'value': null, 'type': 'text'},
        appuser: {'title': 'prefix', 'value': null, 'type': 'text'},
    }
}
